.durations-store-adv .btn-main {
    width: 38px;
    height: 38px;
    padding: 0;
    border-radius: 50px;
}

.durations-store-adv .num-counter-info {
    font-size: 19px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.durations-store-adv .info-count-days .title {
    font-size: 17px;
    font-weight: 600;
    color: var(--gray-05-color);
    padding: 25px 0 15px 0 !important;
}
