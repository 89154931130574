.event-description {
    font-size: 12px;
}

.event-container > footer {
    position: relative;
    z-index: -1;
}

@media screen and (max-width: 568px){
    .event-container {
        .container {
            max-width: unset;
            padding: unset;
        }
    }
}

.sub-event-card {
    position: relative;
    /* height: 150px; */
    overflow: hidden;
    border-radius: 8px;
    background: #000;
    border-bottom: 5px solid #860C15;
}

.sub-event-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.sub-event-card-content {
    position: absolute;
    bottom: 0;
    z-index: 10;
    padding: 0 20px;
    color: white;
    display: flex;
    align-items: center;
    width: 100%;
}

.ticket-btn {
    background: linear-gradient(62.8deg, #860C15 8.03%, #FF0014 140.29%);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px;
}

.ticket-btn:hover {
    background-color: darkred;
}

.sub-event-card-text {
    font-size: 16px;
    font-weight: 600;
}

.sub-event-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0.04deg, #000000 10.72%, rgba(0, 0, 0, 0) 99.96%);
    z-index: 1; /* Makes sure the gradient appears above the image */
}
