.order-status-icon {
    border-radius: 50%;
}

.order-step-icon {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: #F0DBDD;
    color: var(--bs-primary);
}

.active-step {
    background-color: var(--bs-primary);
    border: 2px solid #F0DBDD !important;
    color: #fff !important;
}

.order-content-container {
    background-color: #F0DBDD;
    padding: 2px;
}

.order-payment-content-container {
    padding: 2px;
}
.order-status-divider {
    position: absolute;
    height: 2px;
    background: #DCDCE6;
    width: 12%;
    border-radius: 10px;
    top: 11px;
}

.order-status-divider:nth-child(1) {
    left: 19%;
}

.order-status-divider:nth-child(2) {
    left: 44%;
}

.order-status-divider:nth-child(3) {
    left: 69%;
}