.react-datepicker__header {
    background-color: #9c0c19 !important;
    border-bottom: var(--border-03) !important;
    padding: 15px 0 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #9c0c19;
    color: #fff;
}

.apexcharts-menu {
    background: #fff;
    position: absolute;
    top: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 3px;
    right: -50px !important;
    left: 50px !important;
    opacity: 0;
    min-width: 110px;
    transition: .15s ease all;
    pointer-events: none;
}

.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
    --ag-internal-calculated-line-height: var(--ag-line-height, calc(var(--ag-row-height) - var(--ag-row-border-width)));
    --ag-internal-padded-row-height: calc(var(--ag-row-height) - var(--ag-row-border-width));
    border: 1px solid transparent;
    line-height: min(var(--ag-internal-calculated-line-height), var(--ag-internal-padded-row-height));
    padding-left: calc(var(--ag-cell-horizontal-padding) - 1px);
    padding-right: calc(var(--ag-cell-horizontal-padding) - 1px);
    -webkit-font-smoothing: subpixel-antialiased;
    /* text-align: center; */
    /* margin: auto; */
    text-align: center;
    display: flex;
    align-items: center;
}


.pac-container {
    background-color: #fff;
    position: relative;
    z-index: 9999;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}

.apexcharts-menu.apexcharts-menu-open {
    opacity: 1;
    pointer-events: all;
    transition: .15s ease all;
    right: -82px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #9c0c19 !important;
}

/* Default styles for .btn-primary */
.btn-primary {
    color: #fff;
    background-color: #9c0c19;
    border-color: #9c0c19;
}

/* Hover state */
.btn-primary:hover {
    color: #fff; /* Keep the text color the same */
    background-color: #7a0a14; /* Darker shade for the background */
    border-color: #7a0a14; /* Match the border with the background */
    text-decoration: none; /* Remove any default underline for links */
}

/* Active state (when button is pressed) */
.btn-primary:active {
    color: #fff;
    background-color: #5e070d; /* Even darker shade for pressed button */
    border-color: #5e070d;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); /* Add a slight inset shadow to show it's being pressed */
}

/* Focus state (when button is focused, e.g., after clicking or tabbing through) */
.btn-primary:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 0.2rem rgba(156, 12, 25, 0.5); /* Apply a soft shadow to indicate focus */
}

/* Disabled state */
.btn-primary:disabled {
    color: #fff;
    background-color: #e0e0e0; /* Lighter background color */
    border-color: #e0e0e0; /* Lighter border */
    cursor: not-allowed; /* Change cursor to indicate that it's disabled */
    opacity: 0.65; /* Slightly reduce opacity to indicate it's disabled */
}

/* When the button is in a form submitting state (optional) */
.btn-primary:active:focus {
    outline: none;
    box-shadow: none; /* Prevent additional shadow when active and focused */
}

/* Optional: Add a state for `:visited` or `:link` for anchor buttons */
.btn-primary:link,
.btn-primary:visited {
    color: #fff; /* Keep text color consistent */
    text-decoration: none; /* Remove underline from anchor links */
}
