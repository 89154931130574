.join-us .all-join-us .title {
    font-size: 32px;
    font-weight: 700;
    color: var(--dark-01-color);
}

.join-us .all-join-us .text {
    font-size: 16px;
    font-weight: 500;
    color: var(--gray-03-color);
    padding: 12px 0 !important;
}

.join-us .all-join-us .list-choose .title {
    font-size: 26px;
    padding: 20px 0 !important;
}

.join-us .all-join-us .list-choose .item-list {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-01-color);
    margin-bottom: 10px;
}

.join-us .all-join-us .list-choose .item-list .icon-list {
    padding: 5px 6px;
    background: var(--danger-01-color);
    color: var(--white-01-color);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.join-us .image-silde img {
    border: 3px solid var(--white-01-color);
    border-radius: 16px;
    object-fit: initial !important;
}

.join-us .swiper {
    padding-bottom: 80px;
}

@media screen and (max-width: 550px) {
    .join-us .all-join-us .title,
    .join-us .all-join-us .list-choose .title {
        font-size: 22px;
    }

    .join-us .all-join-us .text {
        font-size: 14px;
    }

    .join-us .all-join-us .list-choose .item-list {
        font-size: 15px;
    }
}
