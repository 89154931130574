.all-payment-content .filter-date,
.all-payment-content .date-form--2 .form-one-date label {
    margin: 0 !important;
}

.all-payment-content .date-form--2 .form-one-date label {
    margin-bottom: 10px !important;
}

.all-payment-content .payment-option {
    cursor: pointer;
    width: 100%;
    border: var(--border-03);
    border-width: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 15px;
    border-radius: 12px;
}

.all-payment-content .payment-option input {
    display: none;
}

.all-payment-content .payment-option img {
    width: 50px;
    height: auto;
}

.all-payment-content .payment-option.selected {
    background: var(--gray-04-color);
    border-color: var(--dark-01-color);
}
