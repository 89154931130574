.footer {
    background: var(--danger-01-color);
    padding-top: 70px;
}

.footer .text {
    font-size: 22px;
    font-weight: 500;
    color: var(--white-01-color);
    line-height: 1.8;
    padding: 15px 0 !important;
}

.footer .follow-news .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--white-01-color);
}

.footer .follow-news .list-social {
    padding-top: 15px !important;
}

.footer .follow-news .list-social .list-one .icon-social {
    width: 40px;
    height: 40px;
    font-size: 17px;
    color: var(--white-01-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: var(--border-01);
    cursor: pointer;
}

.footer .footer-one .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--white-01-color);
    padding-bottom: 12px !important;
}

.footer .footer-one .list-link .nav-link {
    font-size: 15px;
    font-weight: 500;
    color: var(--white-01-color);
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer .final-footer {
    padding: 15px 0;
    margin-top: 50px;
    border-top: var(--border-01);
    border-color: #ffffff66;
}

.footer .final-footer .text-footer {
    font-size: 16px;
    font-weight: 500;
    color: var(--white-01-color);
}

@media screen and (max-width: 360px) {
    .footer .final-footer .text-footer {
        font-size: 14px;
    }

    .footer .follow-news .title {
        font-size: 18px;
    }
}