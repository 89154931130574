.navbar {
    border-bottom: var(--border-03);
    padding: 15px 0;
    background: #fafaff;
}

.navbar .navbar-brand {
    cursor: pointer;
}

.navbar .navbar-nav {
    gap: 15px;
}

html[dir="ltr"] .navbar .navbar-nav {
    gap: 10px;
}

html[dir="ltr"] .navbar .navbar-nav .nav-link {
    font-size: 17px;
    font-weight: 700;
}

@media screen and (max-width: 1199px) {
    .navbar .navbar-nav {
        gap: 0;
    }
}

@media screen and (max-width: 991px) {
    .navbar .navbar-nav {
        gap: 10px;
    }
}

.navbar .nav-item {
    position: relative;
    width: fit-content;
}

.navbar .nav-link {
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-01-color);
    position: relative;
    width: fit-content;
}

.navbar .nav-item.active {
    color: var(--danger-01-color);
}

.navbar .nav-item.active::after {
    content: "";
    position: absolute;
    width: calc(100% - 15px);
    height: 3px;
    background: var(--danger-01-color);
    bottom: -18px;
    right: 50%;
    transform: translateX(50%);
    border-radius: 8px;
}

.navbar .navbar-toggler:focus {
    box-shadow: none;
}

@media screen and (max-width: 991px) {
    .navbar .navbar-nav {
        padding-top: 22px;
    }

    .navbar .nav-item.active::after {
        width: calc(100% - 0px) !important;
        bottom: 0;
    }

    .navbar .all-left-info {
        display: block !important;
    }

    .navbar .main-buttons {
        margin-top: 22px;
    }
}

@media screen and (max-width: 450px) {
    .navbar .btn-main {
        width: 100%;
        font-size: 14px;
    }
}

@media screen and (max-width: 343px) {
    .navbar .main-buttons {
        flex-wrap: wrap;
    }
}

.menu-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation: slideDown 0.5s ease;
    border-bottom: var(--border-03);
    background: var(--white-01-color);
    z-index: 300;
}

/* Keyframes for the animation */
@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.all-lang .lang-one {
    cursor: pointer;
}

html[dir="ltr"] .navbar-nav {
    margin-right: auto;
    margin-left: initial !important;
}

html[dir="ltr"] .navbar-brand {
    margin-right: 1rem;
    margin-left: 0 !important;
}

.main-button-lang .all-lang {
    display: none !important;
}

@media screen and (max-width: 991px) {
    .all-lang {
        display: none !important;
    }

    .main-button-lang .all-lang {
        display: flex !important;
    }
}

@media screen and (max-width: 1199px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        font-size: 17px;
    }
}

@media screen and (max-width: 991px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        font-weight: 700;
    }
}


@media screen and (max-width: 1399px) {
    .navbar .btn-down {
        display: none;
    }
}


@media screen and (max-width: 991px) {
    .navbar .btn-down {
        display: flex;
    }
}
