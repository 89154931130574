.card-box-one .text-info-box {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-06-color);
}

.card-box-one .title-text {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark-01-color);
}

@media screen and (max-width: 1100px) {
    .card-box-one .text-info-box {
        font-size: 13px;
        font-weight: 700;
    }

    .card-box-one .title-text {
        font-size: 15px;
        font-weight: 700;
    }
}

@media screen and (max-width: 991px) {
    .card-box-one .text-info-box {
        font-size: 16px;
        font-weight: 600;
    }

    .card-box-one .title-text {
        font-size: 18px;
        font-weight: 600;
    }
}

@media screen and (max-width: 400px) {
    .card-box-one .text-info-box,
    .card-box-one .title-text {
        font-size: 14px;
        font-weight: 700;
    }
}
