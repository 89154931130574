.header-modal .title {
    font-size: 24px;
    font-weight: 700;
    color: var(--dark-01-color);
    padding-bottom: 12px !important;
}

.header-modal .text {
    font-size: 15px;
    font-weight: 500;
    color: var(--gray-03-color);
    line-height: 1.8;
}

.buttons-Steps .btn-main {
    padding: 5px 25px;
}

/* STEP THREE */
.buttons-check .title {
    font-size: 15px;
    font-weight: 700;
    color: var(--gray-03-color);
    padding: 15px 0 !important;
}

.buttons-check .all-buttons-choose {
    padding: 0 0 15px 0;
    display: flex;
    align-items: center;
    gap: 15px;
}

.radio-label {
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
}

.radio-label input {
    display: none;
}

.radio-label .text-span {
    padding: 6px;
    width: 100px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: var(--border-03);
    border-radius: 8px;
}

.radio-label input:checked + .text-span {
    background-color: #d00c1b1f;
    color: var(--dark-01-color);
    border-color: var(--danger-01-color);
    border-radius: 8px;
}

.radio-label input:checked + .text-span::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    display: flex;
    font-weight: 600;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--danger-01-color);
    border-radius: 50%;
    color: var(--white-01-color);
    font-size: 13px;
}

.file-upload-label {
    display: inline-block;
    background-color: var(--white-01-color);
    border: var(--border-03) !important;
    color: var(--gray-03-color);
    padding: 25px 16px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

#fileInput {
    display: none;
}

.file-preview {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 12px;
    padding-top: 15px;
    flex-wrap: wrap;
}

.file-preview-item {
    position: relative;
}

.file-preview .img-prev {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.file-preview .img-prev img {
    width: 100%;
    height: 180px;
}

.main-image-file {
    width: 224px;
    height: 100%;
}

@media screen and (max-width: 550px) {
    .main-image-file {
        width: 100%;
    }

    .file-preview .img-prev img {
        height: 100%;
    }
}

.remove-button {
    background-color: var(--danger-01-color);
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 0 0 0 5px;
    cursor: pointer;
    margin-top: 5px;
    position: absolute;
    top: -5px;
    right: 0;
}

.btn-main:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.is-valid {
    border-color: #198754 !important;
    background-image: url("") !important;
}

.invalid-feedback {
    font-size: 15px;
    font-weight: 500;
}

.form-modal .country-select .ReactFlagsSelect-module_selectValue__152eS {
    font-weight: 500;
    color: #a8b1ce !important;
}

.form-modal .country-select .ReactFlagsSelect-module_label__27pw9 {
    color: var(--dark-01-color);
}

.all-success-steps .text {
    font-size: 19px;
    font-weight: 500;
    color: var(--dark-01-color);
    line-height: 1.9;
    text-align: center;
}

.all-success-steps .text .link-email {
    color: var(--danger-01-color);
}

.all-success-steps .animtion-lottie {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
}

.all-success-steps .animtion-lottie svg {
    width: 200px !important;
    height: 200px !important;
}

.success-steps .modal-title {
    font-size: 18px;
    font-weight: 700;
}

@media screen and (max-width: 450px) {
    .all-success-steps .text {
        font-size: 16px;
        font-weight: 700;
    }
}

#fileInput1 {
    display: none;
}