/* START MODAL FILTER  */
.modal-filter-result .btn-main.active {
    background: var(--dark-01-color);
    border-color: var(--dark-01-color);
    color: var(--white-01-color);
}

.modal-filter-result .icon {
    width: 22px;
    height: 22px;
    border: var(--border-04);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--gray-06-color);
    transition: all 0.5s;
}

.modal-filter-result .info-top-modal {
    font-size: 15px;
    font-weight: 600;
    color: var(--gray-06-color);
}

.modal-filter-result .filter-date:hover .icon,
.modal-filter-result .icon.active {
    background: var(--green-01-color);
    color: var(--white-01-color);
    border-color: var(--green-01-color);
}

.modal-filter-result .buttons-bottom .btn-main-outline {
    font-size: 14px;
    font-weight: 700;
}

.select-box-option .select-info-option .css-13cymwt-control {
    border: var(--border-04) !important;
}

.select-box-option .select-info-option .css-t3ipsp-control {
    box-shadow: none !important;
}

.select-box-option .select-info-option .css-t3ipsp-control:hover {
    border-color: #dcdcdd !important;
    box-shadow: none;
}

.select-box-option .css-1fdsijx-ValueContainer {
    padding: 0 8px !important;
}

.select-box-option .css-qbdosj-Input {
    margin: 0 !important;
    padding: 0 !important;
}

/* END MODAL FILTER  */
