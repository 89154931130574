/* ======================
START SECTION SLIDER BANNER
============================*/

.banner-main-area {
    position: relative;
    padding: 20px 0;
}

.section-padding {
    padding: 120px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding {
        padding: 80px 0;
    }
}

@media only screen and (max-width: 767px) {
    .section-padding {
        padding: 60px 0;
    }
}

.banner-main-area .banner-one {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 50px !important;
}

.banner-main-area .banner-one::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 44 99 / 39%);
    mix-blend-mode: multiply;
    display: none;
}

.bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
}

.banner-main-area .info-banner {
    padding: 50px 0 40px 0;
}

.info-banner .content-slide {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
}

.info-banner .content-slide .title {
    font-size: 25px;
    width: 100%;
    max-width: 300px;
    padding-bottom: 12px !important;
    color: var(--dark-01-color);
    line-height: 140%;
    font-weight: 700;
}

.info-banner .content-slide .text {
    font-size: 15px;
    color: var(--gray-03-color);
    line-height: 1.8;
    width: 100%;
    max-width: 500px;
    font-weight: 500;
}

/* SCREEN 1199PX */
@media screen and (max-width: 1199px) {
    .info-banner .content-slide {
        justify-content: center;
        align-items: center;
    }
}

/* SCREEN 600PX */
@media screen and (max-width: 600px) {
    .banner-main-area .banner-one {
        padding: 0 0px 100px 0 !important;
    }

    .banner-main-area .info-banner {
        padding: 120px 0 0px 0;
    }

    .scroll-animation {
        bottom: 20%;
    }
}

@media screen and (max-width: 450px) {
    .info-banner .contact-info {
        padding: 30px 15px;
    }
}

@media screen and (max-width: 360px) {
    .info-banner .content-slide .title {
        max-width: 100%;
    }
}

/* =================================
  END BANNER IMAGE
  =================================*/
