.all-branches-vendor .content-brnach-one .right-content-one .title {
    font-size: 18px;
    font-weight: 600;
    color: var(--gray-06-color);
}

.all-branches-vendor .content-brnach-one .right-content-one .text {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-01-color);
    padding-top: 9px !important;
}

.all-branches-vendor .content-brnach-one .actions-buttons-info svg {
    width: 27px;
    height: 27px;
}

.all-branches-vendor
.content-brnach-one
.actions-buttons-info
.icon-trash
svg
path {
    fill: var(--danger-01-color);
}


.location-add-info .button-add-location {
    font-size: 17px;
    font-weight: 600;
    color: var(--green-01-color);
    cursor: pointer;
    padding: 15px 0 0 0;
}