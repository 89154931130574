.not-found-page .animtion-lottie {
    width: 600px;
}

.not-found-page .btn-main {
    width: fit-content;
    margin: auto;
}

@media screen and (max-width: 991px) {
    .not-found-page {
        margin: 0 !important;
        height: 100vh;
    }
}

@media screen and (max-width: 767px) {
    .not-found-page .animtion-lottie {
        width: 450px;
    }
}

@media screen and (max-width: 450px) {
    .not-found-page .animtion-lottie {
        width: 100%;
    }
}
