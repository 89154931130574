.all-table-notification #tableContent > table > tbody > tr > td:nth-child(1) {
    width: 500px;
    text-align: right;
}

.all-table-notification .icon-action-one {
    cursor: pointer;
}

.all-table-notification .icon-action-one svg {
    width: 30px;
    height: 30px;
}

.modal-content--1 .form-one-date {
    flex-direction: column;

    align-items: initial !important;
}

.modal-content--1 .form-label {
    margin: 0;
}

.modal-content--1 .time-input-container {
    position: relative;
}

.modal-content--1 .icon-clock {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 20px;
    color: var(--main-01-color);
}

.modal-content--1 input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
}

@media screen and (max-width: 503px) {
    .info-top-table .btn-send {
        width: 100%;
    }
}

.all-table-notification .table-actions-1 .iconTrash svg path {
    fill: var(--danger-01-color);
}

html[dir="ltr"] .all-table-notification .conent-info-2 {
    text-align: left;
}
