/* ========================================
START CURRENCY CONTENT TAB
========================================*/
.currencies-control
.all-currencies-content
.currency-card-one
.right-content
.title {
    font-size: 18px;
    color: var(--dark-01-color);
    font-weight: 600;
}

.currencies-control
.all-currencies-content
.currency-card-one
.right-content
.text {
    font-size: 15px;
    font-weight: 600;
    color: var(--gray-06-color);
}

.currencies-control
.all-currencies-content
.currency-card-one
.right-content
.green-text {
    font-size: 18px;
    color: var(--green-01-color);
}

.currencies-control
.all-currencies-content
.currency-card-one
.icon-one--1
svg {
    width: 32px;
    height: 32px;
}

.currencies-control
.all-currencies-content
.currency-card-one
.icon-one--1
svg
path {
    fill: var(--gray-06-color);
}

@media screen and (max-width: 450px) {
    .currencies-control
    .all-currencies-content
    .currency-card-one
    .right-content
    .top-content-info {
        margin-top: 2px;
    }
}

/* ========================================
END CURRENCY CONTENT TAB
========================================*/
