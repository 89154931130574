.btn-scroll {
    display: none;
}

.catalog-store-header-icon {
    width: 38px;
    height: 38px;
    background: #ffffff2e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.catalog-store-description {
    font-size: 12px;
    color: #939393;
}

.catalog-banner {
    position: relative;
}

.catalog-banner .title {
    font-size: 16px;
    font-weight: 600;
    color: #370409;
}

.catalog-banner .category {
    font-size: 13px;
    font-weight: 500;
}

.catalog-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../assets/images/pattern.png");
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.1;
    pointer-events: none;
}

@media (max-width: 992px) {
    .container-xl {
        max-width: unset;
        padding: 0 !important;
    }
}