.all-download-app .icon-download {
    padding: 15px 0;
}

.info-down .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
    text-align: center;
    padding: 30px 0 !important;
}

.info-down .apps {
    border-top: var(--border-03);
    padding-top: 25px;
}

.info-down .apps .app-one img {
    border: var(--border-03);
}


@media screen and (max-width: 365px) {
    .info-down .title {
        font-size: 16px;
        font-weight: 700;
    }

}