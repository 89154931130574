* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none !important;
    font-family: "IBM Plex Sans Arabic", sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* d00c1b */
:root {
    --main-01-color: #1e4d6d;
    --danger-01-color: #9c0c19;
    --danger-02-color: #a71013;
    --warning-01-color: #e5a240;
    --dark-01-color: #121212;
    --dark-02-color: #3c3c3c;
    --dark-03-color: #54565b;
    --white-01-color: #ffffff;
    --white-02-color: #fafaff;
    --white-03-color: #fefeff;
    --white-04-color: #f6f6f6;
    --white-05-color: #f8f9fd;
    --gray-01-color: #827f7f;
    --gray-02-color: #edc5c6;
    --gray-03-color: #6a6e83;
    --gray-04-color: #f5f4f9;
    --gray-05-color: #54565b;
    --gray-06-color: #87888b;
    --green-01-color: #32a840;
    --blue-01-color: rgba(47, 101, 204, 1);
    --box-shadow: 0px 30px 70px #524c641f;
    --box-shadow-1: 0px 20px 40px #4345701a;
    --overlay-bg: rgba(0 0 0 / 25%);
    --overlay-01-bg: rgba(0 0 0 / 72%);
    --border-01: 1px solid #ffffff;
    --border-02: 1px solid #d2dadf;
    --border-03: 1px solid #e1dde9;
    --border-04: 1px solid #dcdcdd;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0 !important;
    margin: 0 !important;
}

body {
    background: var(--white-01-color);
    min-height: 100vh;
    overflow: hidden;
    padding: 0 !important;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

img {
    -webkit-user-drag: none;
    -webkit-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.cursor-pointer-event {
    cursor: pointer;
}

.btn-main {
    padding: 5px 20px;
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    background: var(--danger-01-color);
    color: var(--white-01-color);
    border: var(--border-01);
    border-color: var(--danger-01-color);
    border-radius: 8px;
    box-shadow: 0 5px 5px #dddd;
    appearance: button;
    backface-visibility: hidden;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 1.15;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    text-align: center;
}

.btn-dark {
    background: var(--dark-01-color);
    border-color: var(--dark-01-color);
}

.btn-main-outline {
    background: transparent;
    color: var(--dark-01-color);
    border: var(--border-04);
    box-shadow: none;
    transition: all 0.5s;
}

.btn-main-outline:hover {
    color: var(--white-01-color);
}

.btn-main-outline-dark:hover {
    background: var(--dark-01-color) !important;
    border-color: var(--dark-01-color) !important;
}

.btn-main-1,
.btn-main-1:hover {
    background: var(--main-01-color) !important;
    border-color: var(--main-01-color) !important;
    box-shadow: none !important;
}

.btn-main-outline:hover :is(svg path, i) {
    stroke: var(--white-01-color);
}

@media screen and (max-width: 376px) {
    html[dir="ltr"] .btn-main {
        gap: 3px !important;
        font-size: 13px !important;
    }
}

@media screen and (max-width: 353px) {
    html[dir="ltr"] .btn-main {
        gap: 10px !important;
        font-size: 16px !important;
    }
}

.btn-main:active,
.btn-main:hover {
    border-color: var(--danger-01-color);
    background: var(--danger-01-color);
    transform: translateY(-5px);
}

.btn-main:disabled {
    cursor: default;
}

.btn-main:focus {
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    #d00c1c31 0 0 0 4px;
}

.btn-dark:focus {
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    #12121229 0 0 0 4px;
}

.overlay-bg::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--overlay-01-bg);
    top: 0;
    left: 0;
}

.swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    background: #c6c1c1 !important;
    opacity: 1 !important;
    transition: all 0.5s;
}

.swiper-pagination-bullets {
    bottom: 30px !important;
}

.swiper-bullets-color .swiper-pagination-bullet {
    background: var(--gray-02-color) !important;
}

.swiper-pagination-bullet-active,
.swiper-bullets-color .swiper-pagination-bullet-active {
    width: 45px !important;
    height: 10px !important;
    border-radius: 8px !important;
    background: var(--danger-01-color) !important;
}

.card-Section .swiper-pagination-bullets {
    bottom: 0px !important;
}

html[dir="ltr"] .swiper-rtl {
    direction: rtl;
}

.swiper-rtl .swiper-button-prev,
.swiper-rtl .swiper-button-next {
    color: var(--gray-01-color);
    width: 45px;
    height: 45px;
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    transition: all 0.5s;
    cursor: pointer;
    position: absolute;
    bottom: 12px;
    z-index: 1;
    font-size: 19px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s;
    background: var(--white-01-color);
}

.swiper-rtl .swiper-button-prev:hover,
.swiper-rtl .swiper-button-next:hover {
    color: var(--white-01-color);
    background: var(--danger-01-color);
}

.swiper-rtl .swiper-button-prev {
    margin-right: 60px;
}

.swiper-rtl .swiper-button-next {
    margin-left: 60px;
}

.swiper-button-prev:after,
.swiper-button-next::before {
    font-family: "Font Awesome 5 Free";
    display: flex;
    font-weight: 600;
}

.swiper-button-prev:after {
    content: "\f177";
}

.swiper-button-next::before {
    content: "\f178";
}

@media screen and (max-width: 767px) {
    .swiper-rtl .swiper-button-next {
        margin-left: 30px !important;
    }

    .swiper-rtl .swiper-button-prev {
        margin-right: 0 !important;
        margin-left: -30px !important;
    }
}

main {
    position: relative;
    z-index: 0;
}

.form-control {
    text-align: right;
}

html[dir="ltr"] .form-control {
    text-align: left !important;
}

.padding {
    padding-bottom: 70px;
}

/* START FORM INPUT */

.form-style {
    background: var(--white-01-color);
    padding: 30px;
    border-radius: 16px;
    box-shadow: var(--box-shadow-1);
}

.input-pass .error {
    position: absolute;
    bottom: -28px;
}

.input-pass .icon-eye-button {
    position: absolute;
    top: 71%;
    left: 3%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
}

html[dir="ltr"] .input-pass .icon-eye-button {
    right: 3%;
    left: initial;
}

html[dir="ltr"] .form-control.is-invalid,
html[dir="ltr"] .was-validated .form-control:invalid {
    background-position: right calc(0.375em + 0.1875rem) center;
}

.input-pass .form-control.is-invalid,
.was-validated .input-pass .form-control:invalid {
    background-image: url("");
}

.error {
    font-size: 16px;
    font-weight: 500;
    color: red;
}

input::placeholder,
textarea::placeholder {
    font-size: 15px;
    color: #a8b1ce !important;
    font-weight: 500;
}

input,
textarea {
    padding: 12px !important;
    text-align: right !important;
    border-color: #d4d8e5 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: rgb(220, 53, 69) !important;
}

label {
    font-size: 15px;
    color: var(--gray-03-color);
    font-weight: 500;
}

textarea {
    resize: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

/* END FORM INPUT */
/* START SPINNER LOADER */
.loader-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: var(--white-01-color);
    bottom: 0;
    z-index: 1000;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
}

.loader-wrapper.hidden {
    animation: hidden 1.5s;
    animation-fill-mode: forwards;
}

@keyframes hidden {
    100% {
        visibility: hidden;
        opacity: 0;
    }
}

@keyframes spin-1 {
    to {
        transform: rotate(1turn);
    }
}

/* END SPINNER LOADER */

.btn-scroll {
    position: fixed;
    bottom: 30px;
    right: -60px;
    transition: all 0.5s;
    z-index: 0;
    width: 40px;
    height: 40px;
    background: var(--danger-01-color);
    font-size: 20px;
    color: var(--white-01-color);
    border: var(--border-01);
    border-radius: 8px;
    z-index: 12;
}

.btn-scroll.active {
    right: 30px;
}

html[dir="rtl"] .btn-scroll {
    right: initial;
    left: -60px;
}

html[dir="rtl"] .btn-scroll.active {
    left: 30px;
    right: initial;
}

.Toastify__toast-body > div:last-child {
    font-weight: 700;
}

/* .Toastify__toast-container {
  width: max-content !important;
} */
@media screen and (max-width: 480px) {
    .Toastify__toast-container--rtl {
        padding: 10px !important;
    }

    .Toastify__toast-theme--colored {
        margin-bottom: 10px !important;
    }
}

/* ======================================
START CONTENT PAGE DASHBOARD
=========================================*/
.all-page-content {
    min-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

/* START CLASSES */
.margin-top {
    margin-top: 1.5rem;
}

.card-style-1,
.card-style-2 {
    border: var(--border-03);
    background: var(--white-01-color);
    padding: 15px;
    border-radius: 16px;
    height: 100%;
    transition: all 0.5s;
}

.card-style-2 {
    height: auto;
    padding: 22px 15px;
}

.card-style-1:hover,
.card-style-2:hover {
    border-color: var(--danger-01-color);
}

.table-border--1 {
    border: var(--border-03) !important;
}

.green-text {
    color: var(--green-01-color);
}

.blue-text {
    color: var(--blue-01-color);
}

.main-text {
    color: var(--main-01-color);
}

.success-bg-border,
.success-bg,
.danger-bg {
    background: rgba(50, 168, 64, 0.1);
    border: var(--border-01);
    border-color: var(--green-01-color);
    color: var(--green-01-color);
    width: max-content;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.success-bg {
    border: none;
}

.danger-bg {
    background: rgba(168, 50, 50, 0.1);
    border: none;

    color: var(--danger-01-color);
    margin: auto;
}

.line-bg::after {
    content: "";
    position: absolute;
    display: block;
    width: 3px;
    height: 18px;
    border-radius: 0px 30px 0px 30px;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--danger-01-color);
}

html[dir="ltr"] .line-bg::after {
    left: 0;
    right: initial;
}

.all-cards-bottom .card-box-one {
    margin: 0 !important;
}

/* ======================================
END CONTENT PAGE DASHBOARD
=========================================*/

/* SCROLL BAR */
:is(.p-datatable-wrapper,
  .data-table-two,
  .step-marketing-info .main-info-step,
  .notification-content--2 .all-notifictions)::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
:is(.p-datatable-wrapper,
  .data-table-two,
  .step-marketing-info .main-info-step,
  .notification-content--2 .all-notifictions)::-webkit-scrollbar-track {
    background: var(--white-04-color);
    border-radius: 50px;
}

/* Handle */
:is(.p-datatable-wrapper,
  .data-table-two,
  .step-marketing-info .main-info-step,
  .notification-content--2 .all-notifictions)::-webkit-scrollbar-thumb {
    background: var(--gray-06-color);
    border-radius: 50px;
}

:is(.step-marketing-info .main-info-step,
  .notification-content--2 .all-notifictions)::-webkit-scrollbar-thumb {
    background: var(--danger-01-color);
}

.filepond--credits {
    display: none;
}

.filepond--drop-label.filepond--drop-label label {
    font-size: 15px;
    font-weight: 600;
    color: var(--gray-03-color);
}

.custom-select .react-select__menu {
    z-index: 9999;
}

.SingelValue {
    align-items: center;
}

.SingelValue img {
    border-radius: 4px;
}

.PeriodSelect {
    display: flex;
    gap: 10px;

}

.PeriodSelect div {
    width: 50%;
    border: 1px solid #9c0c19;
}

.PeriodSelect div span {
    color: #9c0c19;
    font-weight: bold;
}

.PeriodSelect div span::before {
}

.PeriodSelect div {
    display: flex;
    padding: 10px 15px;
    border-radius: 8px;
    transition: 0.4s;
    gap: 20px;
    cursor: pointer;
}

.PeriodSelect div.Active {
    background-color: #9c0c19;
    color: white;
}

.PeriodSelect div.Active span {
    border: 2px solid white;
}

.PeriodSelect div.Active span::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.4s;
}

.Cardadmin {
    position: relative;
}

.Cardadmin .CardEditBtn {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #9c0c19;
}

.Cardadmin .CardEditBtn svg path {
    fill: white;
}

.react-datepicker-wrapper {
    width: 100%;
}

.time-field .MuiInputBase-root.MuiOutlinedInput-root {
    border: 1px solid #dee2e6;
    padding: 1px;
    border-radius: 4px;
}

.loader {
    width: 32px;
    height: 32px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.details-category h2 {
    line-height: 20px;
}

.details-user h2 {
    line-height: 20px;

}

.content-user .img-user img {
    border-radius: 8px;
}

.header-client-file h2 {
    line-height: 30px;

}

h2 {
    line-height: 30px !important;

}

.pac-container {
    z-index: 9;
}

.icon-trash svg {
    width: 32px;
    height: 32px;
}

.DownloadBtnImage {
    padding: 12px;
    display: flex;
    gap: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    background-color: #6366f1;
    color: white;
}

.cursor-default {
    cursor: default !important;
}

@media (min-width: 992px) {
    .col-5ths {

        width: 20% !important;
    }
}

.react-datepicker__day.react-datepicker__day--disabled {
    color: #ccc !important;
}

.ShowImageBtn {
    padding: 12px;
    display: flex;
    color: white;
    background-color: #1e4d6d;
    margin-top: 15px;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.3s;
}

.ShowImageBtn:hover {
    color: white;
    background-color: #0d2230;

}

.ShowImageBtn:visited {
    color: white;
}

.img-gallery-one {
    position: relative;
}

.GalaryDelete {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #9c0c19;
    color: white;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.GalaryDelete path {
    fill: white;
}