/* START TABS CONTROL */
.tabs-dashboard-control {
    flex-direction: row;
    display: flex;
    gap: 35px;
}

.tabs-dashboard-control .tabs-content-info-tb {
    flex-direction: column;
    border: none;
    gap: 15px;
}

.tabs-dashboard-control .tabs-content-info-tb .nav-item .nav-link {
    width: 200px;
    text-align: right;
    padding: 12px 15px;
    border-radius: 12px;
}

.tabs-dashboard-control .tabs-content-info-tb .nav-item .nav-link.active {
    background: var(--dark-01-color);
    border: none;
    color: var(--white-01-color);
}

.tabs-dashboard-control .tab-content {
    background: var(--white-01-color);
    border: var(--border-03);
    width: 100%;
    padding: 15px;
    height: 100%;
    border-radius: 15px;
}

.table-actions---2 .icon-action-one svg {
    width: 30px;
    height: 30px;
}

.table-actions---2 .icon-action-one svg path {
    fill: var(--gray-06-color);
}

.modal-powers--1 {
    --bs-modal-width: 85%;
}

.modal-powers--1 .data-table-two .table-bordered th:nth-child(1) {
    padding-right: 0;
}

.select-option .css-qbdosj-Input {
    padding: 0;
    margin: 0;
}

.all-powers-table-card .data-table-two table {
    text-align: start;
}

.select-option .css-1fdsijx-ValueContainer {
    padding: 0px 8px;
}

.info-details--check {
    font-size: 17px;
    font-weight: 600;
    color: var(--green-01-color);
}

.info-details--check .icon-check---2 {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: var(--white-01-color);
    background: var(--green-01-color);
    border-radius: 50%;
}

.header-content-tab .title {
    font-size: 17px !important;
    font-weight: 600 !important;
}

@media screen and (max-width: 1100px) {
    .tabs-dashboard-control {
        flex-direction: column;
    }

    .tabs-dashboard-control .tabs-content-info-tb {
        flex-direction: row;
    }

    .tabs-dashboard-control .tabs-content-info-tb .nav-item .nav-link {
        width: max-content;
        font-weight: 700;
        padding: 12px 18px;
        font-size: 15px;
    }
}

@media screen and (max-width: 991px) {
    .all-table-emp .data-table-two,
    .all-powers-table-info .data-table-two {
        overflow-x: auto;
    }

    .all-table-emp .data-table-two .table {
        width: 1200px;
    }

    .all-powers-table-info .data-table-two .table {
        width: 800px;
    }

    .all-table-emp .data-table-two .table-bordered > :not(caption) > * > * {
        text-align: center;
        padding-right: 10px;
    }
}

@media screen and (max-width: 575px) {
    .currencies-control
    .all-currencies-content
    .currency-card-one
    .right-content
    .text,
    .header-content-tab .btn-main {
        font-size: 13px !important;
        font-weight: 700 !important;
    }

    .header-content-tab .title {
        font-size: 14px !important;
        font-weight: 700 !important;
    }

    .currencies-control
    .all-currencies-content
    .currency-card-one
    .right-content
    .title,
    .purchase-card-one .info-right-content .title {
        font-size: 15px !important;
        font-weight: 700 !important;
    }
}
