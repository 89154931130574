.modal-scroll .modal-body {
    height: 400px;
    overflow-y: auto;
}

.modal-scroll .modal-body::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.modal-scroll .modal-body::-webkit-scrollbar-track {
    background: var(--white-04-color);
    border-radius: 50px;
}

/* Handle */
.modal-scroll .modal-body::-webkit-scrollbar-thumb {
    background: var(--main-01-color);
    border-radius: 50px;
}


.modal .modal-header {
    justify-content: space-between;
}

.modal .modal-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.btn-close-icon {
    width: 45px;
    height: 45px;
    border: var(--border-03);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 17px;
    color: var(--dark-01-color);
    cursor: pointer;
    border-radius: 50%;
    border-width: 2px;
    transition: all 0.5s;
}

.btn-close-icon:hover {
    color: var(--white-01-color);
    background: var(--danger-01-color);
    border-color: var(--danger-01-color);
}

.modal::-webkit-scrollbar {
    display: none;
}

.modal .modal-content {
    border-radius: 16px;
    box-shadow: var(--box-shadow-1);
    padding-bottom: 15px;
}
