.sidebar {
    width: 90px;
    height: 100vh;
    background-color: var(--dark-01-color);
    color: var(--white-01-color);
    transition: all 0.3s ease;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
}

html[dir="ltr"] .sidebar {
    right: initial;
    left: 0;
    transition: all 0.5s;
}

html[dir="ltr"] .all-sidebar {
    right: initial;
    left: -120%;
}

html[dir="ltr"] .all-sidebar .overlay-bg-1 {
    left: -120%;
    right: initial !important;
    position: relative;
}

html[dir="ltr"] .sidebar .icon-close {
    right: 0;
    left: initial;
    border-radius: 0 0 0 8px;
}

.icon-open-sidebar {
    width: 38px;
    height: 38px;
    background: #ffffff2e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 15px auto 0 auto;
    cursor: pointer;
}

.all-sidebar.open .sidebar .icon-open-sidebar {
    margin: 15px auto 0 15px !important;
    transform: rotateY(180deg);
    background: var(--danger-01-color);
    color: var(--white-01-color);
}

html[dir="ltr"] .all-sidebar.open .sidebar .icon-open-sidebar {
    margin: 15px 15px 0 auto !important;
}

.all-sidebar {
    position: fixed;
    top: 0;
    right: -120%;
    width: 100%;
    height: 100vh;
    z-index: 3;
}

.all-sidebar .overlay-bg-1 {
    position: fixed;
    top: 0;
    left: 0;
    right: -120%;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.336);
    z-index: -1;
    transition: all 0.4s ease-in-out;
}

.sidebar .icon-close {
    position: absolute;
    left: 0;
    right: initial;
    top: 0;
    border-radius: 0 0 8px 0;
    color: var(--danger-01-color) !important;
    font-size: 20px;
    background: var(--white-01-color);
    width: 40px;
    height: 40px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.content-page-dashboard {
    border-radius: 16px 16px;
    overflow: hidden;
    background: var(--white-04-color);
}

.logo-sidebar {
    width: 60px;
    margin: auto;
    padding: 30px 0 0 0;
}

.logo-sidebar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.all-sidebar.open .sidebar .logo-sidebar {
    width: 80px;
}

.sidebar .navbar-nav {
    padding: 30px 0;
}

.sidebar .navbar-nav .nav-item {
    margin-bottom: 15px;
}

.all-sidebar
.sidebar
.navbar-nav
.nav-item:nth-child(1)
.nav-link.active
.icon-link-nav
:is(svg path),
.all-sidebar
.sidebar
.navbar-nav
.nav-item:last-child
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}

.all-sidebar
.sidebar
.navbar-nav
.nav-item:nth-child(9)
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}

.all-sidebar
.sidebar
.navbar-nav
.nav-item:nth-child(10)
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}

.all-sidebar .sidebar .navbar-nav .nav-item:nth-child(15) {
    border-top: 1px solid var(--dark-02-color);
    padding-top: 15px;
}

.sidebar .navbar-nav .nav-link {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 12px 15px;
    border-right: 7px solid transparent;
    transform: all 0.5s;
    color: var(--white-01-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 28px */
    border-radius: 8px;
}

.sidebar .navbar-nav .nav-link:hover {
    background: #ffffff29;
}

.sidebar .navbar-nav .nav-link.active {
    background: var(--white-01-color);
    color: var(--dark-01-color);
}

.all-sidebar .sidebar .navbar-nav .nav-link {
    margin: 0 15px;
}

body.firefox .all-sidebar .sidebar .navbar-nav .nav-link {
    margin: 0 7px;
}

/* For Webkit browsers */
.sidebar::-webkit-scrollbar {
    display: none;
}

.content-page-dashboard::-webkit-scrollbar {
    display: none;
}

.all-sidebar .navbar-nav .nav-item .icon-link-nav {
    width: 25px;
    height: 25px;
    margin: 0 -4px 0 0;
    transition: all 0.5s;
}

html[dir="ltr"] .all-sidebar .navbar-nav .nav-item .icon-link-nav {
    margin: 0 0px 0 3px;
}

/* SCREEN MIN 991PX */
@media screen and (min-width: 992px) {
    .all-sidebar.open .sidebar,
    html[dir="ltr"] .all-sidebar.open .sidebar {
        width: 295px;
        transition: all 0.4s ease-in-out;
    }

    .tooltip {
        right: 15px !important;
    }

    html[dir="ltr"] .tooltip {
        right: initial !important;
        left: 15px !important;
    }

    .tooltip-inner {
        background-color: var(--dark-01-color);
        padding: 10px 23px !important;
        font-size: 15px;
        font-weight: 600;
        color: var(--white-01-color) !important;
    }

    .content-page-dashboard {
        width: calc(100% - 90px);
        margin: 15px 90px 15px 15px;
        transition: all 0.2s ease-in-out;
    }

    html[dir="ltr"] .content-page-dashboard {
        margin: 15px 15px 15px 90px;
    }

    .content-page-dashboard.activeOpen {
        width: calc(100% - 295px);
        margin-right: 295px;
        transition: all 0.4s ease-in-out;
    }

    html[dir="ltr"] .content-page-dashboard.activeOpen {
        margin-left: 295px;
    }

    .all-sidebar .sidebar .text-link {
        display: none !important;
    }

    .all-sidebar.open .sidebar .text-link {
        display: flex !important;
    }
}

/* SCREEN MAX 991PX */
@media screen and (max-width: 991px) {
    .content-page-dashboard,
    html[dir="ltr"] .content-page-dashboard {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        border-radius: 0;
    }

    html[dir="ltr"] .sidebar {
        left: -120%;
        right: initial;
    }

    html[dir="ltr"] :is(.sidebar.open, .overlay-bg-1.open, .all-sidebar.open) {
        right: initial !important;
        left: 0;
    }

    .sidebar .navbar-nav {
        padding: 30px 0 100px 0;
    }

    .sidebar .icon-close {
        display: flex;
    }

    .icon-open-sidebar {
        display: none;
    }

    .sidebar {
        z-index: 1;
        width: 350px;
        right: -120%;
        transition: right 0.7s ease-in-out;
    }

    .sidebar.open,
    .overlay-bg-1.open,
    .all-sidebar.open {
        right: 0;
    }
}

/* Media query for responsiveness */

@media (max-width: 450px) {
    .sidebar {
        width: 90%;
    }

    .content-page-dashboard .content-page-info {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
