
.category .all-category .category-one {
    border: var(--border-03);
    background: var(--white-01-color);
    box-shadow: var(--box-shadow-1);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-direction: column;
    padding: 15px;
    border-radius: 12px;
    transition: all 0.5s;
    cursor: pointer;
}

.category .all-category .category-one .title {
    font-size: 17px;
    font-weight: 700;
    color: var(--dark-01-color);
    transition: all 0.5s;
}

.category .all-category .category-one:hover {
    border-color: var(--danger-01-color);
}

.category .all-category .category-one:hover .title {
    color: var(--danger-01-color);
}

.category .all-category .swiper {
    padding-bottom: 80px;
}

