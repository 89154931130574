.all-header-financial .col-card:nth-child(1) .card-financial-one {
    background: var(--green-01-color);
}

.all-header-financial .col-card:nth-child(2) .card-financial-one {
    background: var(--blue-01-color);
}

.all-header-financial .col-card:nth-child(3) .card-financial-one {
    background: var(--warning-01-color);
}

.all-header-financial .col-card:nth-child(4) .card-financial-one {
    background: var(--danger-01-color);
}

.all-header-financial .card-financial-one {
    border-radius: 12px;
    color: var(--white-01-color);
    padding: 18px;
}

.all-header-financial .card-financial-one .line-bg::after {
    background: var(--white-01-color);
    right: -5px;
}

.all-header-financial .card-financial-one .title-card {
    font-size: 17px;
    font-weight: 600;
}

.all-header-financial .card-financial-one .card-counter {
    width: fit-content;
    font-size: 20px;
    font-weight: 600;
    padding: 12px 0 0 0;
}

.all-header-financial .card-financial-one .data-bottom-info {
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
}

.all-header-financial .card-financial-one .main-info-bottom {
    padding: 30px 0 0px 0;
}
