.all-tabs-items .all-buttons-export {
    position: absolute;
    left: 16px;
    top: 15px;
}

@media screen and (max-width: 1120px) {
    .all-tabs-items .all-buttons-export {
        position: relative;
        top: initial;
        left: initial;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-bottom: 15px;
    }
}

/* START EVENTS TABS */
:is(.main-event-active, .main-marketing-active)
.all-table-notification
#tableContent
> table
> tbody
> tr
> td:nth-child(1) {
    width: 315px;
}

:is(.main-event-active, .main-marketing-active) .data-table-two {
    overflow-x: auto;
}

.main-event-active .data-table-two table {
    width: 100rem;
}

/* END EVENTS TABS */
/* START TAB ADV STORE */

.main-adv-store--2
.all-table-notification
#tableContent
> table
> tbody
> tr
> td:nth-child(1) {
    width: auto;
    text-align: center;
}

/* END TAB ADV STORE */
