.header-home-vendor .right-content-header .card-hero-one .info-card-top {
    padding-top: 7px;
}

.header-home-vendor
.right-content-header
> .row
> .col-12:nth-child(3)
> .card-hero-one
.icon-img
img {
    width: 100%;
    height: 100%;
}

.header-home-vendor .card-hero-one .content-card-bottom .number-info {
    flex-direction: row-reverse;
    font-size: 21px;
}
