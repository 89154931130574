html[dir="ltr"] .card-hero-one .info-card-top .title .line-bg {
    margin-left: 0;
    margin-right: 7px;
}

html[dir="ltr"] .all-header-bank .card-info-bank .title-card,
html[dir="ltr"] .all-header-financial .card-financial-one .title-card {
    padding-right: 0 !important;
    padding-left: 12px !important;
}

html[dir="ltr"] .form-one-date .date-input .icon-date {
    right: 10px;
    left: initial;
}

html[dir="ltr"] .data-table-two .table-bordered th:nth-child(1),
html[dir="ltr"] .data-table-two .table-bordered td:nth-child(1) {
    padding-left: 12px;
    padding-right: initial;
}

html[dir="ltr"] .all-powers-table-info .data-table-two .table-bordered :is(th, td) {
    text-align: left !important;
}

html[dir="ltr"] .table-reports .card-table-info tr :is(th, td):not(:first-child),
html[dir="ltr"] .table-reports .card-table-info tr :is(th, td) {
    text-align: left;
}

html[dir="ltr"] .tabs-dashboard-control .tabs-content-info-tb .nav-item .nav-link {
    text-align: left;
}

html[dir="ltr"] .chat-button {
    right: 4%;
}

html[dir="ltr"] .content-info-file .content-banner-file :is(.title-content) {
    padding-right: initial !important;
    padding-left: 1rem !important;
}

html[dir="ltr"] .bottom-info-content .col-info:nth-child(1) {
    border-left: none;
    border-right: var(--border-03);
}

html[dir="ltr"] .all-tabs-items .all-buttons-export {
    left: initial;
    right: 16px;
}

html[dir="ltr"] .information-tab .button-edit-info {
    left: initial !important;
    right: 0 !important;
}

/* END MAIN DASHBOARD */
/* START VENDOR DASHBOARD */
html[dir="ltr"] .right-wallet-vendor .button-debt--1 {
    right: 15px;
    left: initial;
}

html[dir="ltr"] #tableOffersVendor .conent-info-2 {
    text-align: left;
}

html[dir="ltr"] .all-branches-vendor .content-brnach-one .actions-buttons-info {
    margin-right: 0 !important;
    margin-left: 3rem;
}

html[dir="ltr"] .all-branches-vendor .text {
    direction: rtl;
}

html[dir="ltr"] .all-branches-vendor .text .num-iban {
    margin-left: 1rem;
    margin-right: 0 !important;
}

.apexcharts-menu {
    background: #fff;
    position: absolute;
    top: 100%;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 3px;
    right: 30px !important;
    opacity: 0;
    min-width: 110px;
    transition: .15s ease all;
    pointer-events: none;
}

/* END VENDOR DASHBOARD */