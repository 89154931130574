.catalog-store-list-container {
    position: relative;
    background-color: #F4E6E7;
    border-radius: 12px;
    padding-bottom: 120px;
}

.catalog-store-category-title::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../../../assets/images/pattern.png");
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.1;
    pointer-events: none;
}

.catalog-store-category-title {
    font-weight: 600;
    color: #46050B;
    padding: 12px 16px;
    border-radius: 12px 12px 0 0;
    position: relative;
    background-color: #F4E6E7;
}

.catalog-store-product-list-container {
    border-radius: 12px 12px 0 0;
}

@media screen and (min-width: 765px) {
    .catalog-store-product-list-container {
        padding:0 20px;
    }
}
