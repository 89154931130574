.step-marketing-info .title {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.step-marketing-info .text {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.8;
    color: var(--dark-01-color);
    padding-top: 12px !important;
}

.step-marketing-info .main-info-step {
    height: 250px;
    overflow-y: auto;
}

.step-marketing-info .form-check-input:checked {
    background-color: var(--danger-01-color);
    border-color: var(--danger-01-color);
}

.step-marketing-info .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(156 12 25 / 14%);
}

/* START STEP FOUR */
.step-four-marketing .info-content-step--4 {
    background: var(--gray-04-color);
    border: var(--border-03);
    border-radius: 12px;
    padding: 30px 15px;
}

.step-four-marketing .info-one :is(.title, .num-info) {
    font-size: 17px;
    font-weight: 600;
    color: var(--gray-05-color);
}

.step-four-marketing .info-one .num-info {
    font-size: 18px;
    color: var(--dark-01-color);
}

/* END STEP FOUR */

.step-two-info .css-qbdosj-Input {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 500px) {
    .step-marketing-info .text {
        font-size: 13px;
    }
}

/* START STEP TWO */
.all-info-interests .main-content-interests .css-qbdosj-Input {
    padding: 0;
    margin: 0;
}

.all-info-interests .main-content-interests .btn-main.active {
    background: var(--danger-01-color);
    border-color: var(--danger-01-color);
    color: var(--white-01-color);
}

/* END STEP TWO */
/* START STEP THREE */
.step-three-marketing .react-datepicker-wrapper {
    width: 100%;
}

/* END STEP THREE */

