.all-table-markting .table-actions-1 .data-table-two {
    overflow: auto;
}

.all-table-markting .table-actions-1 .data-table-two table {
    width: 110rem;


}


