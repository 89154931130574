.card-item-one-1 .title, .card-item-one-1 .text {
    font-size: 22px;
    font-weight: 600;
    color: var(--dark-01-color);
    padding-bottom: 12px !important;
}

.card-item-one-1 .list-item .item-li {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-01-color);
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 15px;
}

.card-item-one-1 .item-li .icon-check {
    width: 26px;
    height: 26px;
    background: var(--main-01-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--white-01-color);
}

.card-item-one-1 .text {
    font-size: 17px;
}