.card-hero-one .info-card-top .title {
    font-size: 19px;
    font-weight: 600;
    color: var(--gray-06-color);
    display: flex;
    gap: 5px;
}

.card-hero-one .info-card-top .title .line-bg {
    display: block;
    width: 3px;
    height: 18px;
    border-radius: 0px 30px 0px 30px;
    margin-left: 7px;
}

.card-hero-one .info-card-top .title .line-bg.blueLine {
    background: var(--blue-01-color);
}

.card-hero-one .info-card-top .title .line-bg.greenLine {
    background: var(--green-01-color);
}

.card-hero-one .info-card-top .title .line-bg.warningLine {
    background: var(--warning-01-color);
}

.card-hero-one .info-card-top .title .line-bg.darkLine {
    background: var(--dark-01-color);
}

.card-hero-one .content-card-bottom .number-info {
    font-size: 25px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.card-hero-one .content-card-bottom .green-color {
    color: var(--green-01-color);
}

.card-hero-one .content-card-bottom .green-color .icon-arrow {
    transform: rotate(50deg);
}

.green-color .icon-arrow {
    transform: rotate(50deg);
}

.green-color {
    color: var(--green-01-color);

}

.red-color .icon-arrow {
    transform: rotate(233deg);

}

.red-color {
    color: var(--danger-01-color);


}

.card-hero-one .content-card-bottom .red-color {
    color: var(--danger-01-color);
}

.card-hero-one .content-card-bottom .red-color .icon-arrow {
    transform: rotate(233deg);
}

.card-hero-one .content-card-bottom :is(.text-per, .icon-arrow, .num-per) {
    font-size: 17px;
    font-weight: 600;
}

.card-hero-one .content-card-bottom .text-per {
    color: var(--gray-06-color);
    font-size: 15px;
}

@media screen and (max-width: 450px) {
    .card-hero-one .info-card-top .title {
        font-size: 16px;
        font-weight: 700;
    }

    .card-hero-one .content-card-bottom .number-info {
        font-size: 18px;
    }

    .card-hero-one .content-card-bottom :is(.text-per, .icon-arrow, .num-per) {
        font-size: 15px;
    }
}
