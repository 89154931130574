.catalog-store-product-container {
    background-color: white;
    border-radius: 12px;
    margin-bottom: 2px;
    height: 160px;
    min-height: 160px;
}

.catalog-store-product-title {
    font-weight: 600;
}

.catalog-store-product-ingredients {
    font-size: 12px;
}

.catalog-store-product-initial-price {
    color: #939393;
    text-decoration: line-through !important;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 4px;
}

.catalog-store-product-final-price {
    font-weight: 600;
    font-size: 18px;
}

.catalog-store-product-action-btn {
    padding: 8px 12px;
}

@media screen and (min-width: 765px) {
    .catalog-store-product-container {
        border-radius: 12px
    }
}