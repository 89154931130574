.sidebar
.nav-item-vendor:nth-child(3)
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}

.sidebar
.nav-item-vendor:nth-child(4)
.nav-link.active
.icon-link-nav
:is(svg path) {
    fill: var(--dark-01-color);
}

.sidebar
.nav-item-vendor:nth-child(5)
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}

.sidebar
.nav-item-vendor:nth-child(6)
.nav-link.active
.icon-link-nav
:is(svg path) {
    fill: var(--dark-01-color);
}

.sidebar
.nav-item-vendor:nth-child(7)
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}

.sidebar
.nav-item-vendor:nth-child(8)
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}

.sidebar
.nav-item-vendor:nth-child(9)
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}

.sidebar
.nav-item-vendor:nth-child(11)
.nav-link.active
.icon-link-nav
:is(svg path) {
    stroke: var(--dark-01-color);
}
