.file-input-container {
    position: relative;
    display: inline-block;
}

.file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.icons-info {
    position: absolute;
    top: 5px;
    left: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.upload-image-info {
    position: relative;
    border: var(--border-03);
    width: 100px !important;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border-radius: 8px;
    margin: 15px 0;
    cursor: pointer;
}

.upload-image-info label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icons-info :is(.edit-icon, .trash-icon) {
    color: var(--white-01-color);
    cursor: pointer;
}
