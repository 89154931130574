.sub-event-card-image {
    position: relative;
    height: 300px;
    overflow: hidden;
    background: #000;
}

.sub-event-card-image::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0.04deg, #000000 10.72%, rgba(0, 0, 0, 0) 99.96%);
    z-index: 1; /* Makes sure the gradient appears above the image */
}

.sub-event-card-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; /* Keeps the image behind the gradient */
}

.sub-event-card-image-content {
    position: absolute;
    bottom: 10px;
    padding: 0 20px;
    color: white;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 2; /* Ensures the content appears above the gradient */
}

.ticket-btn {
    background: linear-gradient(62.8deg, #860C15 8.03%, #FF0014 140.29%);
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px;
}

.ticket-btn:hover {
    background-color: darkred;
}

.sub-event-card-image-text {
    font-size: 14px;
    font-weight: 600;
}

.ticket-list {
    margin-top: 20px;
}

.ticket-card {
    width: 100%;
    margin-bottom: 10px;
}

.ticket-active {
    border-color: #B40D1A;
    color: #B40D1A;
}

.ticket-past {
    border-color: #A1A2A5;
}

.ticket-count {
    font-size: 12px;
}

.ticket-date {
    font-size: 14px;
    margin-bottom: 10px;
}

