.payment-option {
    cursor: pointer;
    width: 100%;
    border: 2px solid #F0DBDD;
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100%;
    padding: 15px;
    border-radius: 12px;
    transition: all 0.2s;
}

.payment-option input {
    display: none;
}

.payment-option-image {
    background: #F4E6E7;
}

.payment-option-image img {
    width: 60px;
    height: 40px;
}

.payment-option.selected {
    background: #F4E6E7;
    border-color: var(--danger-01-color);
    border-width: 2px;
}

.payment-option {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.payment-option input[type="radio"] {
    display: none;
}

.payment-option .circle {
    width: 20px;
    height: 20px;
    border: 2px solid black; /* Black outline */
    border-radius: 50%;
    position: relative;
    margin-left: 10px;
}

.payment-option .circle::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.2s;
}

.payment-option input[type="radio"]:checked + div .circle::before {
    background-color: var(--bs-primary); /* Fill the circle when selected */
}

.payment-option input[type="radio"]:checked + div .circle {
    border-color: var(--bs-primary); /* Fill the circle when selected */
}
