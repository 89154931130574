.header-info-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 15px;
    padding: 15px 0;
}

.header-info-modal .title {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-01-color);
}

.header-info-modal .text {
    font-size: 17px;
    font-weight: 500;
    color: var(--gray-03-color);
}

.form-modal .btn-main {
    margin-top: 20px !important;
}

.form-modal .forget-password {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 12px;
}

.form-modal .forget-password .link-pass {
    font-size: 16px;
    font-weight: 500;
    color: #6e7377;

}
