.header-top-ouro .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
}

/* START MODAL FILTER  */
.modal-filter-result .btn-main.active {
    background: var(--dark-01-color);
    border-color: var(--dark-01-color);
    color: var(--white-01-color);
}

.modal-filter-result .icon {
    width: 22px;
    height: 22px;
    border: var(--border-04);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: var(--gray-06-color);
    transition: all 0.5s;
}

.modal-filter-result .info-top-modal {
    font-size: 15px;
    font-weight: 600;
    color: var(--gray-06-color);
}

.modal-filter-result .filter-date:hover .icon,
.modal-filter-result .icon.active {
    background: var(--green-01-color);
    color: var(--white-01-color);
    border-color: var(--green-01-color);
}

.modal-filter-result .buttons-bottom .btn-main-outline {
    font-size: 14px;
    font-weight: 700;
}

.header-buttons-filter {
    background: var(--white-05-color);
    padding: 15px 20px;
    margin: 20px 0;
    width: 55%;
}

.header-buttons-filter .btn-1 {
    padding: 30px 12px !important;
    background: var(--white-05-color);
    color: var(--dark-02-color);
    border: var(--border-01);
    box-shadow: none;
}

.header-buttons-filter .btn-1.active {
    background: var(--white-01-color);
    border: var(--border-04);
}

@media screen and (max-width: 1199px) {
    .header-buttons-filter {
        width: 70%;
    }
}

@media screen and (max-width: 991px) {
    .header-buttons-filter {
        width: 100%;
    }
}

@media screen and (max-width: 708px) {
    .header-top-ouro {
        gap: 15px !important;
    }
}

@media screen and (max-width: 675px) {
    .right-data-filter .buttons-filter-head {
        width: 100%;
    }

    .right-data-filter .btn-main {
        width: 100%;
    }

    .right-data-filter {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .header-buttons-filter {
        padding: 12px;
    }

    .header-buttons-filter .btn-1 {
        padding: 18px 10px !important;
    }
}

@media screen and (max-width: 359px) {
    .right-data-filter .buttons-filter-head,
    .header-buttons-filter {
        flex-wrap: wrap;
    }
}

/* END MODAL FILTER  */
.top-content-info-ouro--1 .drop-menu-actions {
    text-align: center;
}

.p-datatable table > thead > tr > th:first-child,
html[dir="ltr"] .p-datatable table > thead > tr > th:last-child {
    border-radius: 0 12px 0 0;
}

html[dir="ltr"] .p-datatable table > thead > tr > th:first-child,
.p-datatable table > thead > tr > th:last-child {
    border-radius: 12px 0 0 0;
}
