@media screen and (max-width: 991px) {
    .card-img-map {
        height: 100% !important;
    }

    .table-actions-1 .data-table-two {
        overflow: auto;
    }

    .table-actions-1 .data-table-two table {
        width: 60rem;
    }

    .all-data-table-shop-1
    :is(
      .filter-seach-input,
      .search-filter,
      .buttons-filter-head,
      .new-button
    ) {
        flex-wrap: nowrap !important;
        width: 100%;
    }

    .all-data-table-shop-1 .buttons-filter-head .btn-main {
        width: 100%;
    }
}

@media screen and (max-width: 760px) {
    .table-actions-1 .data-table-two thead th {
        font-size: 14px !important;
    }

    .all-data-table-shop-1 :is(.search-filter) {
        flex-wrap: wrap !important;
    }
}

@media screen and (max-width: 420px) {
    .all-data-table-shop-1 :is(.buttons-filter-head) {
        flex-wrap: wrap !important;
    }
}

