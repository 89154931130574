.main-pending-active
.all-table-notification
#tableContent
> table
> tbody
> tr
> td:nth-child(1) {
    width: auto;
}

.main-pending-active
.all-table-notification
#tableContent
> table
> tbody
> tr
> td:nth-child(2) {
    width: 300px;
}

.main-pending-active .data-table-two {
    overflow-x: auto;
}

.main-pending-active .data-table-two .table {
    width: 84rem;
}
