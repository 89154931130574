.main-advertise-info .info-content-adv .title,
.new-div-select :is(.title, .text),
.details-info-adv :is(.title, .list-one-dev .list-one--1, label) {
    font-size: 17px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.new-div-select {
    text-align: center;
}

.new-div-select .num-text {
    font-size: 28px;
    padding: 16px 0 !important;
}

.main-advertise-info .info-content-adv .country-select {
    width: 400px;
}

.main-advertise-info .info-content-adv .country-select .css-qbdosj-Input {
    margin: 0;
    padding: 0;
}

.main-advertise-info .counter-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.main-advertise-info .counter-info .num-conter-dev {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.main-advertise-info .counter-info .btn-counter {
    width: 45px;
    height: 45px;
    background: transparent;
    border: var(--border-03);
    border-radius: 50%;
    font-size: 17px;
    color: var(--dark-01-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
}

.btn-counter {
    width: 45px;
    height: 45px;
    background: transparent;
    border: var(--border-03);
    border-radius: 50%;
    font-size: 17px;
    color: var(--dark-01-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
}

.main-advertise-info .counter-info .btn-counter:hover {
    background: var(--danger-01-color);
    color: var(--white-01-color);
}

.main-advertise-info .btn-main .spinner-border {
    --bs-spinner-width: 1.5rem;
    --bs-spinner-height: 1.5rem;
}

.details-info-adv .button-list-action .text {
    font-size: 13px;
    font-weight: 700;
}

@media screen and (max-width: 767px) {
    .main-advertise-info .info-content-adv .country-select {
        width: 100%;
    }

    .main-advertise-info {
        display: block !important;
    }
}

.details-info-adv .form-check-input {
    cursor: pointer;
}

.details-info-adv .form-check-input:checked {
    background-color: var(--danger-01-color);
    border-color: var(--danger-01-color);
}

.details-info-adv .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem #9c0c191c;
}


.modal-debt-pay .form-one-date .date-input,
.modal-debt-pay .react-datepicker-wrapper {
    width: 100%;
}