.all-header-advertise .right-content-adv .title {
    font-size: 25px;
    font-weight: 600;
    color: var(--dark-01-color);
    padding-bottom: 15px !important;
}

.all-header-advertise .right-content-adv .text {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-06-color);
    padding-top: 10px !important;
}
