.country-select-option
.ReactFlagsSelect-module_selectOptions__3LNBJ::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.country-select-option
.ReactFlagsSelect-module_selectOptions__3LNBJ::-webkit-scrollbar-track {
    background: var(--white-04-color);
    border-radius: 50px;
}

/* Handle */
.country-select-option
.ReactFlagsSelect-module_selectOptions__3LNBJ::-webkit-scrollbar-thumb {
    background: var(--main-01-color);
    border-radius: 50px;
}

.country-select-option .ReactFlagsSelect-module_selectValue__152eS,
.country-select-option .ReactFlagsSelect-module_selectOptionValue__vS99- {
    gap: 10px;
}

.country-select-option
.country-select
.ReactFlagsSelect-module_flagsSelect__2pfa2 {
    padding-bottom: 0 !important;
}

.country-select-option .country-select .ReactFlagsSelect-module_label__27pw9,
.country-select-option
.country-select
.ReactFlagsSelect-module_secondaryLabel__37t1D {
    font-weight: 500;
}

.country-select-option
.country-select
.ReactFlagsSelect-module_selectBtn__19wW7 {
    border-color: #dee2e6 !important;
}

.country-select-option
.country-select
.ReactFlagsSelect-module_selectBtn__19wW7 {
    padding: 6px 10px;
}

html[dir="ltr"]
.country-select-option
.country-select
.ReactFlagsSelect-module_label__27pw9 {
    padding-left: 0;
    padding-right: 10px;
}
