.header-reports-vendor
.right-content-header
> .row
> .col-12:nth-child(1)
> .card-hero-one
.icon-img
img {
    width: 100%;
    height: 100%;
}


.all-tabs-items-report .all-buttons-export {
    top: 50px;
}


@media screen and (max-width: 1120px) {
    .all-tabs-items-report .all-buttons-export {
        position: relative;
        top: 0;
        left: 0;
    }
}