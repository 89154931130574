.all-chat-info {
    width: 700px;
    margin: 50px auto 0 auto;
}

.filter-message .info-message-content {
    padding: 15px 0;
}

.filter-message .info-message-content .message-one {
    background: var(--white-04-color);
    width: 100%;
    padding: 15px;
    position: relative;
    margin-top: 15px;
    border-radius: 12px;
    position: relative;
}

.filter-message .info-message-content .message-one.new-message {
    background: var(--dark-01-color);
}

.filter-message .info-message-content .message-one.new-message::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    background: var(--dark-01-color);
    right: 0;
    top: 0;
    border-radius: 8px;
}

.filter-message
.info-message-content
.message-one.new-message
.text-user-message {
    color: var(--dark-01-color) !important;
}

.filter-message .info-message-content .message-one .text-user-message {
    font-weight: 600 !important;
    padding-top: 5px !important;
    color: var(--dark-01-color) !important;
}

.filter-message .info-message-content .message-one .badge-num {
    display: none;
}

.filter-message .info-message-content .message-one.new-message .badge-num {
    background: var(--dark-01-color);
    display: flex;
}

.filter-message .info-message-content .message-one .user-info .text-user {
    width: 100%;
}

.filter-message .info-message-content .message-one .user-info,
.filter-message
.info-message-content
.message-one
.user-info
.text-user
.head-message {
    display: flex;
    align-items: center;
    gap: 15px;
}

.filter-message
.info-message-content
.message-one
.user-info
.text-user
.head-message {
    justify-content: space-between;
}

.chat-container {
    border: var(--border-03);
    border-radius: 12px;
    background: var(--white-01-color);
    padding: 15px 25px;
}

.chat-container .chat-header {
    border-bottom: var(--border-03);
    padding: 15px;
}

.chat-container .chat-header .user-info {
    display: flex;
    align-items: center;
    gap: 15px;
}

.chat-container .chat-header .user-avatar {
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
    border-radius: 50%;
}

.chat-container .chat-header .name-user,
.filter-message
.info-message-content
.message-one
.user-info
.text-user
.name-user {
    font-size: 17px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.chat-container .chat-messages {
    padding: 20px 0;
    overflow: auto;
    height: 360px;
}

.chat-container .chat-messages::-webkit-scrollbar {
    display: none;
}

.chat-container .info-chat {
    width: max-content;
    background: var(--white-04-color);
    padding: 13px 30px;
    border-radius: 16px;
    margin-bottom: 10px;
    line-height: 1.8;
}

.chat-container .info-chat .message,
.filter-message
.info-message-content
.message-one
.user-info
.text-user
.text-user-message {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-01-color);
    width: 100%;
    max-width: 550px;
}

.chat-container .info-chat.support-chat {
    background: var(--dark-01-color);
    color: var(--white-01-color);
    border-bottom-right-radius: 0;
    margin-left: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.chat-container .info-chat.support-chat .message {
    color: var(--white-01-color);
}

.chat-container .info-chat.user-send {
    border-bottom-left-radius: 0;
    margin-right: auto;
}

.chat-container .info-chat .date-send,
.filter-message
.info-message-content
.message-one
.user-info
.text-user
.date-send {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-01-color);
}

.chat-container input::placeholder,
.chat-container input {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-01-color);
}

@media screen and (max-width: 767px) {
    .all-chat-info {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .chat-container {
        padding: 15px;
    }

    .chat-container .chat-header {
        padding: 5px 5px 15px 5px;
    }

    .chat-container .info-chat .message {
        font-size: 13px;

        max-width: 100%;
    }
}

@media screen and (max-width: 361px) {
    .filter-message .info-message-content .message-one {
        padding: 10px;
    }

    .info-message-content .message-one .user-info .text-user .text-user-message,
    .info-message-content .message-one .user-info .date-message {
        font-size: 12px !important;
    }

    .info-message-content .message-one .user-info .text-user .name-user {
        font-size: 14px !important;
    }

    .info-message-content .message-one .badge-num {
        top: 3px;
        right: 10px;
    }
}

.chat-button {
    position: fixed;
    bottom: 50px;
    right: 10%;
    z-index: 999;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: var(--danger-01-color);
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
}

.chat-button:before,
.chat-button::after {
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0rem;
    background-color: red;
    margin: auto;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: anim-1 3s linear infinite;
    animation: anim-1 3s linear infinite;
}

@keyframes anim-1 {
    0% {
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }

    70% {
        opacity: 0.09;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}


@media screen and (max-width: 325px) {
    .all-info-header-chat .buttons-chat {
        flex-wrap: wrap;
    }
}