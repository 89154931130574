.header-data-export .btn-main:hover {
    background: transparent !important;
    border-color: var(--danger-01-color);
}

.header-data-export .btn-main {
    width: 50px;
    height: 50px;
    padding: 0;
}

@media screen and (max-width: 307px) {
    .header-data-export .btn-main {
        width: 40px;
        height: 40px;
        padding: 0;
    }

    .header-data-export {
        flex-wrap: wrap;
    }
}
