.modal-info-pay {
    --bs-modal-width: 900px;
}

.modal-info-pay .modal-dialog {
    padding: 0 15px;
}

.modal-info-pay
.all-table-notification
#tableContent
> table
> tbody
> tr
> td:nth-child(1) {
    width: auto;
    text-align: center;
}

@media screen and (max-width: 575px) {
    .modal-info-pay .modal-dialog {
        padding: 0;
    }
}
