.tabs-content-info-tb .nav-item .nav-link {
    font-size: 17px;
    font-weight: 600;
    color: var(--gray-06-color);
    border: none;
}

.tabs-content-info-tb .nav-item .nav-link.active {
    color: var(--dark-01-color);
    border-bottom: 2px solid var(--dark-01-color);
    box-shadow: none;
}
  