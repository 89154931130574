.catalog-store-order-confirm-container {
    position: fixed;
    bottom: 0;
    border-top: 1px solid #DCE1E5;
    width: 100%;
    padding: 12px;
    z-index: 10000;
    background: white;
}

.catalog-store-order-final-price {
    font-size: 13px;
    font-weight: 600;
    color: #A1A2A5;
}


.catalog-store-order-watch-order {
    font-size: 13px;
    font-weight: 600;
    color: #2F65CC;
}

/* Default styles for the catalog-store-order-cart */
.catalog-store-order-cart {
    position: fixed;
    bottom: -100%; /* Initially hidden, off the screen */
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Add some shadow for the bottom sheet effect */
    border-radius: 20px 20px 0 0; /* Rounded top corners */
    padding: 20px;
    transition: bottom 0.3s ease-out; /* Smooth transition for sliding */
    max-height: 70vh;
    overflow: auto;
}

.catalog-store-order-cart.cart-show {
    bottom: 111px;
}

.catalog-store-order-cart .content {
    overflow-y: auto; /* Allows scrolling if content is too long */
}

.catalog-store-order-cart.cart-show + .cart-backdrop {
    display: block;
}

.cart-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.catalog-store-order-discount {
    position: relative;
    background-color: #F4E6E7;
    padding: 8px;
}

.catalog-store-order-discount p {
    color: #46050B;
    font-weight: 600;
}

.catalog-store-order-discount::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../../../assets/images/pattern.png");
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.1;
    pointer-events: none;
}

.catalog-store-order-list-container {
    padding-bottom: 72px;
    min-height: 100vh;
}


.catalog-store-order-list {
    background-color: #F0DBDD;
}

.catalog-store-order-list .catalog-store-product-container {
    margin-bottom: 2px;
}

.catalog-store-order-factor {
    background-color: #F0DBDD;
    margin-top: 2px;
}

.catalog-store-total-price {
    font-size: 18px;
    color: #46050B;
}