/* START HEADER VENDOR */
.right-wallet-vendor .button-debt--1 .btn-main {
    box-shadow: none;
    background: var(--green-01-color);
    border-color: var(--green-01-color);
}

.right-wallet-vendor .button-debt--1 {
    position: absolute;
    top: 15px;
    left: 15px;
}

.all-info-progress-wallet .progress-info {
    width: fit-content;
}

.all-info-progress-wallet :is(.num-progress .text, .title) {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.all-info-progress-wallet .info-content .text {
    font-size: 15px;
    font-weight: 600;
    color: var(--gray-06-color);
}


/* END HEADER VENDOR */

.modal-info-vendor-wallet .select-option-one {
    display: none;
}
