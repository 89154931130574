@media screen and (max-width: 586px) {
    .header-client-file .left-info-content--002 {
        align-items: start !important;
    }
}

.modal-info-edit--1 .btn-main-outline {
    color: var(--danger-01-color);
}

.modal-info-edit--1 .btn-main-outline:hover {
    background: var(--danger-01-color);
    color: var(--white-01-color);
}