.all-top-categories {
    overflow-y: auto;
    height: 500px;
    direction: ltr;
    padding: 15px;
}

.all-top-categories::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.all-top-categories::-webkit-scrollbar-track {
    background: var(--white-04-color);
    border-radius: 50px;
}

/* Handle */
:is(.all-top-categories, .modal-scroll .modal-body)::-webkit-scrollbar-thumb {
    background: var(--danger-01-color);
    border-radius: 50px;
}

.all-top-categories .category-top-one .name-user {
    color: var(--dark-01-color);
}

.all-top-categories .category-top-one :is(.name-user, .num-one) {
    font-size: 17px;
    font-weight: 600;
}

@media screen and (max-width: 450px) {
    .all-top-categories {
        padding: 15px 15px 15px 0;
    }

    .all-top-categories .category-top-one .img-country img {
        width: 20px;
        height: 20px;
    }

    .all-top-categories .category-top-one :is(.name-user, .num-one) {
        font-size: 15px;
        font-weight: 700;
    }
}
