
.all-icons-table .icon-info-1 svg {
    width: 23px;
    height: 23px;
}

.all-icons-table .icon-info-1.active svg path {
    fill: var(--green-01-color);
}

