.all-pagination .page-item .page-link {
    color: var(--main-01-color);
    font-size: 17px;
    font-weight: 600;
    border-radius: 12px;
    transition: all 0.5s;
    background: transparent;
    border-color: var(--main-01-color);
    padding: 8px 17px !important;
}

.all-pagination .page-item.active-1 .page-link,
.all-pagination .page-item:hover .page-link {
    background: var(--main-01-color) !important;
    border-color: var(--main-01-color) !important;
    color: var(--white-01-color) !important;
}

.all-pagination .pagination {
    flex-wrap: wrap;
    justify-content: center;
}
