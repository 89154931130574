.store-details
.content-info
:is(.title, .text, .text-num, .info-contact-one) {
    font-size: 17px;
    font-weight: 600;
    color: var(--gray-06-color);
    margin-top: 12px;
    transition: all 0.5s;
}

.store-details {
    background-color: #F4E6E7;
    padding: 16px;
    border-radius: 12px;
}

.store-details .content-info .info-contact-one {
    color: var(--dark-01-color);
}

.store-details .content-info .info-contact-one svg path {
    fill: var(--danger-01-color);
}

.store-details .content-info .info-contact-one:hover {
    color: var(--danger-01-color);
}

.store-details .content-info .text {
    font-size: 15px;
}
