.info-table-tech table tbody tr {
    border-right: var(--border-04);
    border-width: 3px;
    border-color: transparent;
}

.info-table-tech table tbody tr:hover {
    background: var(--white-04-color);
    border-color: var(--dark-02-color);
}

html[dir="ltr"] .info-table-tech table tbody tr {
    border-left: var(--border-04);
    border-right: 0;
    border-width: 3px;
    border-color: transparent;
}

html[dir="ltr"] .info-table-tech table tbody tr:hover {
    border-color: var(--dark-02-color);
}

@media screen and (max-width: 991px) {
    .info-table-tech .data-table-two {
        overflow: auto;
    }

    .info-table-tech .data-table-two table {
        width: 1000px;
    }
}
