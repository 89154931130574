.all-cards .card {
    text-align: center;
    background: var(--white-01-color);
    border-radius: 16px;
    box-shadow: var(--box-shadow-1);
    border: none;
}

.all-cards .card img {
    border-radius: 16px 16px 0 0;
}

.all-cards .card .card-title {
    font-size: 22px;
    color: var(--dark-01-color);
    font-weight: 500;
}

.all-cards .card-text {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-01-color);
    padding: 12px 0 5px 0 !important;
    height: 70px;
}

html[dir="ltr"] .all-cards .card-text {
    height: 110px;
}

@media screen and (max-width: 1399px) {
    .all-cards .card-text {
        height: 90px;
    }
}

@media screen and (max-width: 767px) {

    .all-cards .card-text,
    html[dir="ltr"] .all-cards .card-text {
        height: auto;
    }
}

.all-cards .btn-main {
    margin: 15px auto 0 auto;
    padding: 8px 35px;
    height: 45px;
}

.card:hover .img-front {
    /* transform: rotateY(-180deg); */
}

.card:hover .img-back {
    /* transform: rotateY(0deg); */
}

.imgs-rotate .img-front {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(0deg);
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.imgs-rotate .img-back {
    border: 1px solid #dfe9f1;
    position: absolute;
    border-radius: 20px;
    width: 100%;
    height: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.05);
    background-color: #fff;
    top: 0;
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/* .card:hover .img-back {
  transform: rotateY(0deg);
} */