/* START MODAL UPDATE TRANSFERS */
.modal-update-transfers .p-fileupload-advanced .p-fileupload-buttonbar {
    background: transparent;
    border: none;
}

.modal-update-transfers .p-fileupload-content {

    border: none;

}

.modal-update-transfers .p-fileupload {
    margin: auto;
}

/* END MODAL UPDATE TRANSFERS */
