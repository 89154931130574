.navbar-header-page {
    background: var(--white-01-color);
    padding: 1.2rem 1rem;
}

.icon-toggle-sidebar {
    width: 40px;
    height: 40px;
    display: none;
    justify-content: center;
    align-items: center;
    border: var(--border-03);
    font-size: 17px;
    color: var(--dark-01-color);
    border-radius: 8px;
    transition: all 0.5s;
}

.icon-toggle-sidebar:hover {
    background: var(--danger-01-color);
    color: var(--white-01-color);
    border-color: var(--danger-01-color);
}

.navbar-header-page .navbar-right-info :is(.title, .name-user) {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.navbar-header-page .navbar-right-info .name-user {
    color: var(--gray-05-color);
}

@media screen and (max-width: 991px) {
    .icon-toggle-sidebar {
        display: flex;
    }
}

.nav-item-vendor-button {
    display: none;
}

@media screen and (max-width: 630px) {
    .btn-advertise {
        display: none;
    }

    .nav-item-vendor {
        display: block;
    }

    .nav-item-vendor .btn-advertise {
        display: flex;
        box-shadow: none;
        margin: 0 15px;
    }
}

@media screen and (max-width: 450px) {
    .navbar-header-page .navbar-right-info :is(.title, .name-user) {
        font-size: 16px;
        font-weight: 700;
    }

    .navbar-header-page :is(.icon-notification, .icon-settings) img {
        width: 24px;
        height: 24px;
    }

    .navbar-header-page .image-user img {
        width: 37px;
        height: 37px;
    }

    .navbar-header-page .lang-dropmenu .btn,
    .navbar-header-page .lang-dropmenu .dropdown-menu .dropdown-item {
        font-size: 14px;
        font-weight: 700;
    }

    .navbar-header-page .main-info-details-nav {
        gap: 12px !important;
    }

    .navbar-header-page {
        padding: 1rem 10px !important;
    }
}

.drop-menu-content--1 .dropdown-toggle {
    background: transparent !important;
    border: none !important;
    padding: 0;
}

.drop-menu-content--1 .dropdown-menu {
    --bs-dropdown-min-width: 100%;
    --bs-dropdown-padding-y: 0rem;
    transform: translate(0px, 60px) !important;
    overflow: hidden;
}

.drop-menu-content--1 .dropdown-toggle::after {
    display: none;
}

.drop-menu-content--1 .nav-link {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-01-color);
    text-align: center;
    padding: 8px;
}

.drop-menu-content--1 .dropdown-item {
    transition: all 0.5s;
}

.drop-menu-content--1 .dropdown-item:nth-child(2) {
    background: var(--danger-01-color);
}

.drop-menu-content--1 .dropdown-item:hover {
    background: var(--danger-01-color);
    color: var(--white-01-color);
}

.drop-menu-content--1 .dropdown-item:hover .nav-link,
.drop-menu-content--1 .dropdown-item:nth-child(2) .nav-link {
    color: var(--white-01-color);
}

.drop-menu-content--1 .dropdown-item:active {
    background: transparent;
}
