
.ouro-top .all-ouro-top .ouro-top-one {
    border: var(--border-03);
    background: var(--gray-04-color);

    padding: 12px;
    border-radius: 8px;
}

.ouro-top .all-ouro-top .ouro-top-one .num-top {
    font-size: 17px;
    font-weight: 700;
    color: var(--gray-03-color);
}

.ouro-top .all-ouro-top .ouro-top-one .title-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.ouro-top .all-ouro-top .ouro-top-one .num-users {
    font-size: 18px;
    font-weight: 700;
    color: var(--danger-01-color);
}

.ouro-top .btn-main {
    padding: 5px 32px;
    margin-top: 25px !important;
}