.modal-delete-customer .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.modal-delete-customer .text {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-06-color);
    line-height: 1.7;
    padding-top: 12px !important;
}

@media screen and (max-width: 420px) {
    .modal-delete-customer .title {
        font-size: 16px;
        font-weight: 700;
    }

    .modal-delete-customer .text {
        font-size: 14px;
    }
}
