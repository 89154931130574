.table-width .p-datatable-table {
    min-width: 130vw !important;
}

@media screen and (max-width: 991px) {
    .all-customer-page-info
    :is(
      .filter-seach-input,
      .search-filter,
      .buttons-filter-head,
      .new-button
    ) {
        flex-wrap: nowrap !important;
        width: 100%;
    }

    .all-customer-page-info .buttons-filter-head .btn-main {
        width: 100%;
    }
}

@media screen and (max-width: 1060px) {
    .table-width .p-datatable-table {
        min-width: 200vw !important;
    }
}

@media screen and (max-width: 767px) {
    .all-customer-page-info :is(.search-filter) {
        flex-wrap: wrap !important;
    }
}

@media screen and (max-width: 689px) {
    .table-width .p-datatable-table {
        min-width: 280vw !important;
    }
}

@media screen and (max-width: 492px) {
    .table-width .p-datatable-table {
        min-width: 400vw !important;
    }

    .table-customer .content-user {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
}

@media screen and (max-width: 350px) {
    .table-width .p-datatable-table {
        min-width: 400vw !important;
    }

    .table-customer h2 {
        font-size: 14px !important;
    }
}

@media screen and (max-width: 420px) {
    .all-customer-page-info :is(.buttons-filter-head) {
        flex-wrap: wrap !important;
    }
}
