.table-reports .card-table-info tr :is(th, td):not(:first-child),
.table-reports .card-table-info tr :is(th, td) {
    text-align: right;
}

.button-category--1 {
    width: max-content;
    padding: 11px;
    border: var(--border-03);
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.5s;
    cursor: pointer;
}

.button-category--1:hover {
    background: var(--main-01-color);
    color: var(--white-01-color);
    border-color: var(--main-01-color);
}

@media screen and (max-width: 575px) {
    .buttons-bottom-11 {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 390px) {
    .all-tabs-items-reports .tabs-content-info-tb .nav-item .nav-link {
        font-size: 13px;
        font-weight: 900;
    }
}
