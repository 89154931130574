.pay-info---1 {
    padding: 12px 20px;
    background: var(--white-04-color);
    font-size: 17px;
    font-weight: 600;
    color: var(--dark-01-color);
    border-radius: 12px;
    cursor: pointer;
    width: max-content;
}

.main-current-active .data-table-two .table {
    width: 110rem !important;
}
