.header-client-file .image-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header-client-file :is(.name-text, .title) {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.header-client-file .form-check input {
    width: 45px;
}

.header-client-file
:is(.status-file, .info-contact-one, .all-interests-content .title) {
    font-size: 17px;
    font-weight: 600;
    color: var(--gray-06-color);
    margin-top: 12px;
    transition: all 0.5s;
}

.content-contact .info-contact-one:hover {
    color: var(--main-01-color);
}

.switch-input {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
}

.switch-input input {
    display: none;
}

.switch-input .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.switch-input .slider:before {
    background-color: var(--white-01-color);
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
}

.switch-input input:checked + .slider {
    background-color: var(--main-01-color);
}

.switch-input input:checked + .slider:before {
    transform: translateX(26px);
}

.switch-input .slider.round {
    border-radius: 34px;
}

.switch-input .slider.round:before {
    border-radius: 50%;
}

/* END RIGHT CONTENT */

/* START LEFT CONTENT */
.content-info-file {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;

    padding: 35px 25px;
    border-radius: 16px;
}

.content-info-file
.content-banner-file
:is(.title-content, .num-content-info, .equals-content, .end-img) {
    font-size: 17px;
    font-weight: 600;
    color: var(--white-01-color);
}

.content-info-file .content-banner-file :is(.num-content-info) {
    font-size: 30px;
    padding-top: 10px !important;
}

.content-info-file .content-banner-file .equals-content {
    font-size: 20px;
}

.content-info-file .end-img {
    margin-top: 20px;
}

.content-info-file .end-img img {
    width: 83px;
    height: 53px;
    border-radius: 5px;
    margin-left: 10px;
}

/* SCREEN 991px */
@media screen and (max-width: 991px) {
    .header-client-file .left-content-file {
        margin-top: 15px;
    }

    .content-info-file .left-image-banner {
        margin: initial !important;
    }
}

/* SCREEN 446px */
@media screen and (max-width: 446px) {
    .content-info-file .left-image-banner {
        margin: auto !important;
    }

    .content-info-file .left-image-banner img {
        width: 100% !important;
        height: 100% !important;
    }

    .header-client-file :is(.name-text, .title) {
        font-size: 15px;
    }

    .header-client-file :is(.status-file) {
        font-size: 13px !important;
        font-weight: 700 !important;
    }

    .right-content-file .info-conten {
        flex-wrap: wrap;
    }
}

/* END LEFT CONTENT */

/* ============================
START MIDDLE CONTENT ========
=============================*/
.middle-content {
    padding: 30px 15px;
    text-align: center;
}

.middle-content .all-middle-content .middle-content-one {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.middle-content .all-middle-content .col-content:not(:first-child) {
    border-right: var(--border-04);
    padding-right: 15px;
}

.middle-content
.all-middle-content
.middle-content-one
:is(.title, .info-title) {
    font-size: 17px;
    font-weight: 600;
    color: var(--gray-06-color);
}

.middle-content .all-middle-content .middle-content-one .info-title {
    color: var(--dark-01-color);
}

@media screen and (max-width: 450px) {
    .middle-content
    .all-middle-content
    .middle-content-one
    :is(.title, .info-title) {
        font-size: 14px !important;
        font-weight: 700 !important;
    }
}

/* ============================
END MIDDLE CONTENT ========
=============================*/

/* END CONTENT INFO  */
@media screen and (max-width: 991px) {
    .content-tabs-info---1 .data-table-two {
        overflow: auto;
    }

    .content-tabs-info---1 .data-table-two table {
        width: 84rem;
    }

    .content-tabs-info---1 .table-transfers---2 table,
    .content-tabs-info---1 .deposite-operation table {
        width: 42rem;
    }

    .content-tabs-info---1 .login-out-table table {
        width: 37rem;
    }

    .content-tabs-info---1 .main-pending-active table tr {
        text-align: center;
    }
}

/* END CONTENT INFO  */
