.notification-fixed {
    position: fixed;
    top: 0;
    right: -120%;
    z-index: 100;
    height: 100vh;
    background: transparent;
    width: 100%;
}

.notification-fixed.active {
    right: 0;
}

.overlay-notification {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.411);
    right: -120%;
    z-index: 2;
    transition: all 0.4s;
}

.overlay-notification.active {
    right: 0;
}

.modal-notification .all-notifictions .notification-one .text {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-02-color);
    line-height: 1.8;
}

.modal-notification .all-notifictions .notification-one .date-notification {
    font-size: 14px;
    font-weight: 600;
    color: var(--gray-06-color);
    padding-top: 10px !important;
}

.notification-content--2 {
    background: var(--white-01-color);
    border: var(--border-03);
    width: 400px;
    position: fixed;
    z-index: 3;
    top: 0;
    right: -120%;
    transition: all 0.8s;
}

.notification-fixed.active .notification-content--2 {
    right: 0;
}

@media screen and (max-width: 440px) {
    .notification-content--2 {
        width: 350px;
    }
}

@media screen and (max-width: 370px) {
    .notification-content--2 {
        width: 95%;
    }
}

@media screen and (max-width: 290px) {
    .notification-content--2 {
        width: 98%;
    }
}

.notification-content--2 .all-notifictions {
    height: 100vh;
    overflow-y: auto;
    padding: 15px 15px 80px 15px;
}

.modal-notification .title-top {
    border-bottom: var(--border-03);
    padding: 15px;
}

.modal-notification .title-top .title {
    font-size: 22px;
    font-weight: 600;
    color: var(--dark-02-color);
}

.modal-notification .title-top .icon-close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: var(--dark-02-color);
    border: var(--border-03);
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.5s;
}

.modal-notification .title-top .icon-close:hover {
    border-color: var(--danger-01-color);
    color: var(--danger-01-color);
}

@media screen and (max-width: 360px) {
    .modal-notification .all-notifictions .notification-one .text {
        font-size: 13px;
        font-weight: 700;
    }

    .modal-notification .modal-body {
        padding: 1rem 1rem 1rem 0.7rem !important;
    }
}