.statistics .all-statistics .statistics-one {
    border: var(--border-03);
    padding: 15px;
    background: var(--gray-04-color);
    border-radius: 8px;
    transition: all 0.5s;
}

.statistics .all-statistics .statistics-one:hover {
    border-color: var(--danger-01-color);
}

.statistics .all-statistics .statistics-one .title {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-01-color);
}

.statistics .all-statistics .statistics-one .text {
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-01-color);
    padding-top: 6px !important;
}

html[dir="ltr"] .statistics .all-statistics .statistics-one .text {
    font-weight: 600;
}