.info-data-one .form-change-unmber {
    width: 90px;
}

.bottom-info-content .col-info:nth-child(1) {
    border-left: var(--border-03);
}

.bottom-info-content .date-info-content-one .text-title {
    position: relative;
}

.bottom-info-content .date-info-content-one .text-title::after {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50px;
    right: -22px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--white-01-color);
    position: absolute;
}

.bottom-info-content .col-info:nth-child(1) .text-title::after {
    background: var(--green-01-color);
}

.bottom-info-content .col-info:nth-child(2) .text-title::after {
    background: var(--warning-01-color);
}

.left-content-file .content-banner-file .line-bg::after {
    background: var(--white-01-color);
}
