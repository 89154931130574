.p-rowgroup-header {
    display: none;
}


.p-datatable-tbody tr td {
    text-align: right;
}

/* START DROPMENU ACTIONS */
.drop-menu-actions .dropdown-toggle,
.drop-menu-actions .btn:hover {
    background: transparent;
    border-radius: 0;
    border: none;
}

.drop-menu-actions .dropdown-toggle::after {
    display: none;
}

.drop-menu-actions .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    color: var(--dark-02-color);
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    border-radius: 0.375rem;
}

.drop-menu-actions .menu-item:hover {
    background-color: #dcdcdc31;
}

.drop-menu-actions .menu-item:nth-child(3) {
    color: var(--main-01-color);
    fill: var(--main-01-color);
}

.drop-menu-actions .dropdown-item.active,
.drop-menu-actions .dropdown-item:active,
.drop-menu-actions .dropdown-item:hover {
    background: transparent !important;
}

/* END DROPMENU ACTIONS */

.card-table-info tr {
    border-radius: 16px;
}

.card-table-info .p-datatable {
    border: var(--border-04);
    border-radius: 12px;
    overflow: hidden;
}

.p-datatable-thead {
    border-bottom: var(--border-04);
}

.card-table-info tr:not(:last-child) {
    border-bottom: var(--border-04);
}

.card-table-info tr :is(th, td) {
    padding: 20px;
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.card-table-info tr :is(th, td):not(:first-child) {
    border-right: var(--border-04);
}

html[dir="ltr"] .card-table-info tr :is(th, td):not(:first-child) {
    border-left: var(--border-04);
    border-right: none;
}

.card-table-info .p-column-header-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* START DATA TABLE TWO  */
.data-table-two {
    border: var(--border-04);
    border-radius: 12px;
    margin: 0;
    background-color: var(--white-01-color);
}

.data-table-two table {
    text-align: center;
    margin: 0;
    overflow: hidden;
    background: var(--white-01-color);
    border-radius: 12px;
}

.data-table-two tbody tr {
    vertical-align: middle;
    border: none;
}

.data-table-two thead tr {
    background: var(--white-04-color) !important;
}

.data-table-two thead th {
    padding: 15px 0;
    color: var(--gray-06-color);

    background: transparent !important;
}

.data-table-two tbody tr td {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.data-table-two tbody tr td .success-bg {
    margin: auto;
}

.data-table-two .table > :not(caption) > * > * {
    box-shadow: none;
}

.data-table-two .table-bordered > :not(caption) > * > * {
    border: none !important;
}

.data-table-two thead tr {
    border: none !important;
    border-bottom: var(--border-04) !important;
}

.data-table-two tbody tr td {
    background: transparent !important;
}

.data-table-two .table-bordered th:nth-child(1) {
    padding-right: 12px;
    padding-left: 0;
}

/* END DATA TABLE TWO  */

@media screen and (min-width: 1545px) {
    .data-table-two .table {
        width: 100% !important;
    }
}
