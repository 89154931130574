.banner .all-banner {
    background: linear-gradient(-90deg, #cc0c1a, #a71013);
    padding: 55px 35px;
    border-radius: 12px;
    position: relative;
}

.banner .all-banner .title {
    font-size: 30px;
    font-weight: 600;
    color: var(--white-01-color);
}

.banner .all-banner .text {
    font-size: 16px;
    font-weight: 400;
    color: var(--white-01-color);
    padding: 15px 0 !important;
    width: 100%;
    max-width: 500px;
    line-height: 1.8;
}

html[dir="ltr"] .banner .all-banner :is(.title, .text) {
    text-align: left;
}

.banner .all-banner .form-email .btn-main {
    box-shadow: none;
    background: var(--white-01-color);
    border-color: var(--white-01-color);
    color: var(--danger-01-color);
}

.banner .all-banner .form-email .btn-main:hover {
    transform: translateY(0);
}

.banner .all-banner .form-email .form-control {
    width: 350px;
    padding: 10px;
    font-size: 15px;
    font-weight: 400;
    color: var(--white-01-color);
    background: transparent;
}

.banner .all-banner .form-email .form-control::placeholder {
    color: var(--white-01-color) !important;
}

.banner .timer-banner {
    justify-content: center;
}

.banner .timer-banner .timer-one {
    background: #ffffff26;
    width: 75px;
    padding: 10px;
    border-radius: 9px;
    font-size: 22px;
    font-weight: 700;
    color: var(--white-01-color);
}

.banner .timer-banner .timer-one .text-time {
    font-size: 15px;
}

.banner .all-banner-join {
    margin-top: 150px;
}

.banner .all-banner .image-mobile {
    position: absolute;
    top: 15%;
    transform: translateY(-50%);
    left: 0;
    animation: animtion1 2s infinite alternate;
}


@keyframes animtion1 {
    0% {
        transform: translateX(50px) translateY(-50%);
    }

    100% {
        transform: translateX(-50px) translateY(-50%);
    }
}

.apps .app-one img {
    border-radius: 8px;

}

@media screen and (max-width: 1199px) {
    .banner .all-banner .form-email .form-control {
        width: 300px;
    }
}

@media screen and (max-width: 991px) {
    .banner .all-banner {
        text-align: center;
    }

    .banner .all-banner .text {
        max-width: 100%;
    }

    .banner .all-banner .all-form {
        justify-content: center;
    }

    .banner .all-timer-banner .timer-banner {
        justify-content: center;
        margin: 25px 0 0 0;
    }

    /* BANNER JOIN */
    .banner .all-banner .image-mobile {
        top: 30%;
    }

    .banner .all-banner-join {
        margin-top: 50px;
    }

    .banner .all-banner .image-mobile img {
        width: 370px;
        height: 370px;
    }
}

@media screen and (max-width: 767px) {
    .banner .all-banner-join {
        margin-top: 0px;
    }

    .banner .all-banner .image-mobile img {
        width: 300px;
        height: 300px;
    }

    .banner .all-banner {
        padding: 50px 15px;
    }

    html[dir="ltr"] .banner .all-banner :is(.title, .text) {
        text-align: center;
    }

    /* BANNER JOIN */
    .banner .all-banner .image-mobile {
        position: relative;
        top: 0;
        transform: translateY(0);
        margin-top: 25px;
    }

    .banner .apps {
        justify-content: center;
    }


    @keyframes animtion1 {
        0% {
            transform: translateX(0) translateY(0);
        }

        100% {
            transform: translateX(0) translateY(8%);
        }
    }
}

@media screen and (max-width: 550px) {
    .banner .all-banner .title {
        font-size: 22px;
    }

    .banner .all-banner .text {
        font-size: 15px;
    }

    .banner .all-banner .all-form {
        flex-wrap: wrap;
    }

    .banner .all-banner .all-form .btn-main,
    .banner .all-banner .form-email .form-control,
    .banner .form-one {
        width: 100%;
    }

    .apps img {
        width: 100%;
        height: 40px;
    }
}

@media screen and (max-width: 471px) {
    .apps img {
        width: 150px;
        height: 100%;
    }
}


@media screen and (max-width: 369px) {
    .apps img {
        width: 130px;

    }
}

@media screen and (max-width: 329px) {
    .apps img {
        width: 230px;

    }
}

@media screen and (max-width: 265px) {
    .apps img {
        width: 200px;

    }
}

@media screen and (max-width: 350px) {
    .banner .all-timer-banner .timer-banner {
        gap: 5px !important;
    }

    .banner .all-banner .image-mobile img {
        width: 100%;
        height: 100%;
    }
}

.all-info-timer-success {
    text-align: center;
}

.all-info-timer-success .text-timer-finish {
    font-size: 25px;
    font-weight: 700;
    color: var(--white-01-color);
    border: var(--border-03);
    padding: 12px 25px !important;
    border-radius: 8px;
}

html[dir="ltr"] .banner .all-banner .image-mobile {
    left: initial !important;
    right: 0;
}

.banner .all-banner-join {
    text-align: right;
}

@media screen and (max-width: 767px) {
    .banner .all-banner-join {
        text-align: center;
    }
}
