.hero-section {
    padding: 70px 0 0px 0;
    margin-bottom: 70px;
}

@media screen and (max-width: 991px) {
    .hero-section {
        padding: 70px 0 70px 0;
    }
}

@media screen and (max-width: 767px) {
    .hero-section {
        padding: 70px 0 0px 0;
    }
}

.bg-image-hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.hero-section .all-hero-section :is(.text-head, .text, .text-image) {
    font-size: 17px;
    font-weight: 500;
    color: var(--dark-01-color);
}

.hero-section .all-hero-section .text-head {
    font-size: 22px;
    color: var(--danger-01-color);
    font-weight: 700;
    text-transform: capitalize;
}

.hero-section .all-hero-section .text {
    color: var(--dark-01-color);
    font-size: 15px;
    line-height: 1.8;
    text-transform: capitalize;
}

.hero-section .all-hero-section .title {
    font-size: 30px;
    font-weight: 600;
    color: var(--dark-01-color);
    padding: 15px 0 !important;
    line-height: 1.8;
    text-transform: capitalize;
}

@media screen and (max-width: 991px) {
    html[dir="ltr"] .hero-section .all-hero-section .title {
        font-size: 26px;
    }
}

.hero-section .all-hero-section .main-buttons {
    padding-top: 22px;
}

.hero-section .all-hero-section .text-image {
    left: -40px;
    top: 40%;
    transform: translateY(-50%) rotate(-90deg);
    position: absolute;
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

@media screen and (max-width: 991px) {
    .hero-section .all-hero-section .text-image {
        left: -60px;
    }
}

@media screen and (max-width: 575px) {
    .hero-section .all-hero-section .text-image {
        left: -25px;
    }
}

.hero-section .all-hero-section .text-image .line-text {
    width: 50px;
    height: 3px;
    background: #9695954d;
    display: block;
    position: absolute;
    left: -60px;
    bottom: 22%;
    transform: translateY(-50%);
    overflow: hidden;
}

html[dir="ltr"] .hero-section .all-hero-section .text-image .line-text {
    bottom: 35%;
}

.hero-section .all-hero-section .text-image .line-text::after {
    content: "";
    width: 25px;
    height: 4px;
    background: var(--dark-01-color);
    position: absolute;
    left: 100%;
    display: block;
    animation: animLine 2s infinite ease-in-out;
}

@keyframes animLine {
    form {
        left: 0;
    }
    to {
        left: -100%;
    }
}

.hero-section .btn-dark {
    padding: 5px 28px;
}

@media screen and (max-width: 530px) {
    .hero-section .all-hero-section .text-head {
        font-size: 20px;
    }

    .hero-section .all-hero-section .text {
        font-size: 13px;
    }

    .hero-section .all-hero-section .title {
        font-size: 30px;
        font-weight: 700;
    }
}

@media screen and (max-width: 420px) {
    .hero-section .all-hero-section .text-image {
        font-size: 15px;
        left: -35px;
    }

    .hero-section .all-hero-section .main-buttons .btn-main {
        width: 100%;
        font-size: 14px;
    }
}

@media screen and (max-width: 353px) {
    .hero-section .all-hero-section .main-buttons {
        flex-wrap: wrap;
    }
}

html[dir="ltr"] .hero-section .all-hero-section .text-image {
    left: initial !important;
    right: -60px;
}

@media screen and (max-width: 575px) {
    html[dir="ltr"] .hero-section .all-hero-section .text-image {
        right: -30px;
    }
}
