.form-one-date .date-input {
    position: relative;
}

.form-one-date .date-input .icon-date {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color: var(--main-01-color);
}

.react-datepicker__header {
    background-color: #1e4d6d !important;
    border-bottom: var(--border-03) !important;
    padding: 15px 0 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
    color: var(--white-01-color) !important;
}

.react-datepicker__day,
.react-datepicker__time-name {
    color: var(--dark-01-color) !important;
    font-size: 14px;
    font-weight: 600;
}

.react-datepicker__day--selected {
    color: var(--white-01-color) !important;
}

.react-datepicker {
    border: 1px solid #aaacb1c2 !important;
}

.react-datepicker__navigation {
    top: 13px !important;
}

.react-datepicker__navigation-icon::before {
    border-color: var(--white-01-color) !important;
}
