.lang-dropmenu .btn,
.lang-dropmenu .dropdown-menu .dropdown-item {
    background: transparent !important;
    color: var(--dark-01-color);
    font-size: 17px;
    font-weight: 600;
    border: none !important;
}

.lang-dropmenu .dropdown-toggle {
    padding: 0;
}

.lang-dropmenu .dropdown-toggle::after {
    margin-right: 0.7em;
}

html[dir="ltr"] .lang-dropmenu .dropdown-toggle::after {
    margin-left: 0.7em;
    margin-right: 0;
}

.lang-dropmenu .dropdown-menu {
    transform: translate(-13px, 44px) !important;
}

html[dir="ltr"] .lang-dropmenu .dropdown-menu {
    transform: translate(14px, 44px) !important;
}

.lang-dropmenu .dropdown-menu .dropdown-item {
    font-size: 15px;
}

.lang-dropmenu .dropdown-menu {
    --bs-dropdown-min-width: 6rem;
    padding: 0;
    border: var(--border-03);
}

.lang-dropmenu .dropdown-menu .dropdown-item {
    padding: 5px 10px;
    transition: all 0.5s;
}

.lang-dropmenu .dropdown-menu .dropdown-item:hover {
    background: var(--danger-01-color) !important;
    color: var(--white-01-color) !important;
}

.lang-dropmenu .btn-check:checked + .btn,
.lang-dropmenu .btn.active,
.lang-dropmenu .btn.show,
.lang-dropmenu .btn:first-child:active,
:not(.lang-dropmenu .btn-check) + .lang-dropmenu .btn:active {
    color: var(--dark-01-color);
}

html[dir="ltr"] .dropdown-lang-landing .dropdown-menu {
    transform: translate(7px, 44px) !important;
}

@media screen and (max-width: 991px) {
    .dropdown-lang-landing {
        display: none;
    }

    .all-lang .dropdown-lang-landing {
        display: flex !important;
    }
}

@media screen and (max-width: 450px) {
    .lang-dropmenu .dropdown-menu {
        transform: translate(-3px, 40px) !important;
    }

    html[dir="ltr"] .lang-dropmenu .dropdown-menu {
        transform: translate(3px, 40px) !important;
    }
}

@media screen and (max-width: 330px) {
    .dropdown-lang-landing {
        display: flex;
    }

    .all-lang .dropdown-lang-landing {
        display: none !important;
    }

    html[dir="ltr"] .dropdown-lang-landing .dropdown-menu {
        transform: translate(0px, -35px) !important;
    }
}
