.header-section {
    padding-bottom: 50px;
    text-align: center;
}

.header-section .title {
    font-size: 24px;
    font-weight: 700;
    color: var(--dark-01-color);
}


.header-section .text {
    font-size: 17px;
    font-weight: 500;
    color: var(--gray-03-color);
}