.information-branche {
    width: 500px;
}

.information-branche .icon-arrow svg {
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 575px) {
    .information-branche {
        width: 100%;
    }

}

@media screen and (max-width: 575px) {
    .information-branche .content-info .title {
        font-weight: 700;

    }
}