/* TABLE TABS */
.all-tabs-items .button-add-new {
    position: absolute;
    top: 10px;
    left: 15px;
}

.all-table-pending .badge {
    font-size: 15px;
    font-weight: 600;
    padding: 10px 12px;
}

@media screen and (max-width: 1283px) {
    .all-table-pending .table-actions-1 .data-table-two {
        overflow: auto;
    }

    .all-table-pending .table-actions-1 .data-table-two table {
        width: 90rem;
    }
}

@media screen and (max-width: 630px) {
    .all-tabs-items .button-add-new {
        position: relative;
        top: initial;
        left: initial;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 450px) {
    .all-tabs-items .button-add-new .btn-main {
        width: 100%;
    }

    .all-table-p .data-table-two table {
        text-align: start;
    }
}
