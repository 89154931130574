.all-cards-img .card-item-add .text {
    font-size: 17px;
    color: var(--dark-01-color);
    font-weight: 600;
}

.uploadImg-11 {
    position: relative;
}

.uploadImg-11 input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.modal-add-new-card .button-add-input {
    font-size: 17px;
    font-weight: 700;
    color: var(--green-01-color);
}

.file-pond-upload .filepond--drop-label.filepond--drop-label label {
    font-size: 14px;
    font-weight: 600;
    color: var(--dark-01-color);
}

@media screen and (max-width: 550px) {
    .all-inputs-add {
        flex-wrap: wrap;
    }

    .all-inputs-add .main-input-add {
        width: 100%;
    }
}
