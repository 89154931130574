.breadcrumb-page .breadcrumb .breadcrumb-item {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.breadcrumb-page .breadcrumb .breadcrumb-item .link-text {
    color: var(--gray-06-color) !important;
    transition: all 0.5s;
}

.breadcrumb-page .breadcrumb .breadcrumb-item:hover .link-text {
    color: var(--danger-01-color) !important;
}


html[dir="ltr"] .breadcrumb-item + .breadcrumb-item::before {
    float: left;

    padding-right: 0.5rem;
}