.header-chart-info .title {
    font-size: 20px;
    font-weight: 700;
    color: var(--dark-01-color);
}

.chart-info .apexcharts-legend-series {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chart-info .header-chart-info .main-info-bottom {
    font-size: 17px;
    font-weight: 600;
    color: var(--gray-06-color);
}

.chart-info .header-chart-info .main-info-bottom .num-pers {
    color: var(--green-01-color);
}

.chart-info .apexcharts-toolbar {
    right: initial !important;
    left: 3px;
}

.chart-info .num-info {
    font-size: 22px;
    font-weight: 600;
    color: var(--dark-03-color);
}

.chart-info .text,
.chart-info .chart-content-info .data-one .number-data {
    font-size: 15px;
    font-weight: 600;
    color: var(--gray-06-color);
    padding-top: 8px !important;
}

.chart-info .chart-content-info .data-one .title {
    font-size: 17px;
    font-weight: 600;
    color: var(--dark-01-color);
    position: relative;
    padding-right: 8px !important;
}

.chart-info .chart-content-info .data-one .title::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--green-01-color);
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
}

.chart-info .chart-content-info .data-one .number-data {
    padding: 0 !important;
    font-size: 17px;
}

.chart-info-three .header-chart-info .title {
    color: var(--gray-06-color);
    font-size: 17px;
}

.chart-info-three .apexcharts-legend-text {
    font-size: 13px !important;
    font-weight: 600 !important;
    font-family: "IBMPlexSansArabic";
}

.chart-info-three .apexcharts-legend {
    gap: 12px;
}

.chart-info-three .apexcharts-legend-marker {
    width: 30px !important;
    border-radius: 3px !important;
}

.chart-info-three .apexcharts-legend {
    top: 50% !important;
    transform: translateY(-50%);
}

.counter-progress .counter-progress-one {
    width: 100px;
}

@media screen and (max-width: 450px) {
    .header-chart-info .title {
        font-size: 16px;
    }

    .chart-info .chart-content-info .data-one .title,
    .chart-info .chart-content-info .data-one .number-data {
        font-size: 15px;
    }
}
