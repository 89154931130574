.header-search-filter .icon-filter-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    cursor: pointer;
}

.header-search-filter input {
    padding-right: 42px !important;
    font-size: 17px;
    font-weight: 600;
    color: var(--gray-06-color) !important;
}

@media screen and (max-width: 825px) {
    .header-search-filter .search-filter {
        flex-wrap: nowrap !important;
        width: 100%;
    }

    .header-search-filter .filter-seach-input {
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    .header-search-filter .search-filter {
        flex-wrap: wrap !important;
    }

    .header-search-filter .buttons-filter-head,
    .header-search-filter .buttons-filter-head .btn-main {
        width: 100%;
    }
}
